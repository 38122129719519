import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PricePreviewPayload } from './price-preview.model';
import { PricePreview } from '../types/types';
import { RequestError } from '../generic-errors';

export const PricePreviewActions = createActionGroup({
  source: 'Price Preview',
  events: {
    Load: props<{ payload: PricePreviewPayload }>(),
    'Load Succeeded': props<{ prices: PricePreview[] }>(),
    'Load Failed': props<{ error: RequestError }>(),
    'Increase Member Quantity': emptyProps(),
    'Decrease Member Quantity': emptyProps(),
  },
});
