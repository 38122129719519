import { Component, inject } from '@angular/core';
import { SwStateMachineService } from '../../services/sw-state-machine.service';

@Component({
  selector: 'ava-smart-weather-loading',
 template: `
   <div [style]="'display: flex; justify-content: center'">
     <i nz-icon nzType="loading-3-quarters" nzSpin [style]="'font-size: 40px'"></i>
   </div>

 `
})
export class LoadingComponent {
  swSms = inject(SwStateMachineService)
}
