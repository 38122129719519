import { Component, Inject, Input, OnInit } from '@angular/core';
import { combineLatest, debounceTime, filter, Observable, of } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { WINDOW } from '@ng-web-apis/common';
import { Button, ButtonOptions, ButtonService, ButtonStyle } from './service';
import { TokenService } from '../../services/token';
import { LinkService } from '../../services/link';
import { DataReadService } from '../../services/data-read';
import { GLOBAL_RX_STATE, GlobalState, StateService } from '../../services/state';
import { RxEffects } from '@rx-angular/state/effects';
import { tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { BlockState } from '../../block/services/state';
import { QuillEditorOptions, QuillEditorService } from '../quill-editor/service';
import { FieldType } from '../../services/field';
import { FormService } from '../../services/form';
import { ColorType } from '../../services/color';

@Component({
  selector: 'ava-button-view',
  templateUrl: './view.html',
  providers: [RxState, RxEffects],
})
export class ButtonViewComponent implements OnInit {
  @Input() stateId: string | undefined;
  @Input() rowId: string | undefined;
  @Input() columnIndex: number | undefined;
  @Input() options: ButtonOptions | undefined;
  globalState$ = this.globalState.select();
  blockState: RxState<BlockState> | undefined;
  blockState$: Observable<BlockState> | undefined;
  formGroup: FormGroup | undefined;
  buttonField: Button | undefined;
  tokenizedValue: Button | undefined;
  styles: ButtonStyle = this.buttonService.buttonStyle;
  buttonTextOptions: QuillEditorOptions;
  href: string = '';

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private buttonService: ButtonService,
    private dataReadService: DataReadService,
    private linkService: LinkService,
    private tokenService: TokenService,
    public stateService: StateService,
    private formService: FormService,
    protected quillService: QuillEditorService,
    private rxEffects: RxEffects,
  ) {
    const buttonTextOptions = quillService.options;
    buttonTextOptions.label = {
      placeholder: 'text',
      singular: 'Button',
    };
    if (buttonTextOptions.toolbarOptions) {
      buttonTextOptions.toolbarOptions.buttons = ['bold', 'italic', 'underline', 'subscript', 'superscript'];
    }
    this.buttonTextOptions = buttonTextOptions;
  }

  get buttonValue$(): Observable<any> {
    if (this.blockState && this.rowId) {
      return this.blockState.select('blockValue', 'rows', this.rowId).pipe(
        filter((field) => field.fieldType === FieldType.BUTTON),
        tap((field) => {
          this.buttonField = field as Button;
        }),
      );
    }
    return of(null);
  }

  get tokenizeButtonValue$(): Observable<any> {
    if (this.blockState && this.rowId) {
      return combineLatest([
        this.blockState.select('blockValue', 'rows', this.rowId),
        this.globalState.select('tokenRefresh'),
      ]).pipe(
        filter(([field]) => field.fieldType === FieldType.BUTTON),
        debounceTime(100),
        tap(([field]) => {
          const buttonField = field as Button;
          buttonField.value = this.tokenService.tokenize(buttonField.value);
          this.tokenizedValue = buttonField;
          let href = '';
          if (this.tokenizedValue) {
            href = this.linkService.href(this.tokenizedValue);
          }
          this.href = href;
        }),
      );
    }
    return of(null);
  }

  get styles$(): Observable<any> {
    if (this.blockState && this.rowId) {
      return combineLatest([
        this.blockState.select('blockValue', 'rows', this.rowId),
        this.blockState.select('blockWidth'),
      ]).pipe(
        filter(([field]) => field.fieldType === FieldType.BUTTON),
        tap(([field]) => {
          const buttonField = field as Button;
          this.styles.backgroundColorClass = buttonField.color === ColorType.C_30 ? 'ant-btn-red' : 'ant-btn-blue';
        }),
      );
    }
    return of(null);
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId];
    }
    if (this.blockState) {
      this.blockState$ = this.blockState.select();
      // this.rxEffects.register(this.styles$)
      this.rxEffects.register(this.tokenizeButtonValue$);
      this.rxEffects.register(this.buttonValue$);
      this.rxEffects.register(this.styles$);
    }
  }

  linkClick(event: Event): void {
    event.preventDefault();
    if (this.tokenizedValue) {
      this.linkService.linkClick(this.tokenizedValue);
    }
  }
}
