import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  domain: string;

  constructor() {
    if (environment.firebaseConfig.projectId === 'avagate-wss-1') {
      this.domain = 'https://www.wssfiles.com';
    } else {
      this.domain = 'https://storage.googleapis.com/' + environment.firebaseConfig.storageBucket;
    }
  }
}
