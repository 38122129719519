import { Component, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { BatteryQuoterService } from '../../battery-quoter.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'ava-form',
  templateUrl: './form.component.html',
  standalone: true,
  imports: [CommonModule, NzFormModule, NzSelectModule, FormsModule, NzButtonModule, NgOptimizedImage],
})
export class FormComponent implements AfterViewInit {
  @ViewChild('container') container: ElementRef | undefined;
  containerWidth: number | undefined;
  gridTemplateColumns: { [key: string]: string } = {};
  gridButtonColumn: { [key: string]: string } = {};

  constructor(public bs: BatteryQuoterService) {}

  ngAfterViewInit(): void {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.containerWidth = this.container?.nativeElement.offsetWidth;
    this.gridTemplateColumns = { 'grid-template-columns': '360px' };
    this.gridButtonColumn = { 'grid-column': '1' };
    if (this.containerWidth && this.containerWidth >= 700) {
      this.gridTemplateColumns = { 'grid-template-columns': '360px 360px' };
      this.gridButtonColumn = { 'grid-column': '2' };
    }
    if (this.containerWidth && this.containerWidth >= 1080) {
      this.gridTemplateColumns = { 'grid-template-columns': '360px 360px 360px' };
    }
  }
}
