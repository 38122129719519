import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Observable, of, tap } from 'rxjs';
import { RxEffects } from '@rx-angular/state/effects';

@Directive({
  selector: '[avaDateFormat]',
})
export class DateFormatDirective implements OnInit {
  @Input() avaDateFormat: boolean | undefined;
  previousLength: number = 0;

  constructor(
    private rxEffects: RxEffects,
    private elementRef: ElementRef,
    private control: NgControl,
  ) {}

  get avaDateFormat$(): Observable<any> {
    return (
      this.control.valueChanges?.pipe(
        tap((rawString) => {
          if (rawString.length >= this.previousLength) {
            const inputElement: HTMLInputElement = this.elementRef.nativeElement;
            let string = '';
            const separator = '/';
            for (const character of rawString) {
              if (/^\d$/.test(character) || character === separator) {
                string += character;
              }
            }
            string = string.replace(/^(\d\d)(\d\d)(\d{4})$/, '$1' + separator + '$2' + separator + '$3');
            string = string.replace(/^(\d\d)(\d\d\/\d{4})$/, '$1' + separator + '$2');
            string = string.replace(/^(\d\d\/\d\d)(\d{4})$/, '$1' + separator + '$2');
            if (string.length === 2 && /^\d\d$/.test(string)) {
              string = string + separator;
            }
            if (string.length === 5 && /^\d\d\/\d\d$/.test(string)) {
              string = string + separator;
            }
            if (string.length > 10) {
              string = string.substring(0, 10);
            }
            string = string.replace(/^(\d)\/(\d\d)\/(\d{4})$/, '0$1' + separator + '$2' + separator + '$3');
            string = string.replace(/^(\d\d)\/(\d)\/(\d{4})$/, '$1' + separator + '0$2' + separator + '$3');
            inputElement.value = string;
            this.previousLength = string.length;
          }
          if (rawString.length < this.previousLength) {
            this.previousLength = rawString.length;
          }
        }),
      ) || of(null)
    );
  }

  ngOnInit(): void {
    if (this.avaDateFormat) {
      this.rxEffects.register(this.avaDateFormat$);
    }
  }
}
