import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FirestoreConnectorService } from './services/firestore-connector.service';

import { AgentSchedulerComponent } from './agent-scheduler.component';
import { AgentComponent } from './components/steps/agent/agent.component';
import { AgentsListComponent } from './components/steps/agents-list/agents-list.component';
import { ConfirmationComponent } from './components/steps/confirmation/confirmation.component';
import { MeetingComponent } from './components/steps/meeting/meeting.component';
import { RegionComponent } from './components/steps/region/region.component';
import { TopicComponent } from './components/steps/topic/topic.component';
import { UserInfoComponent } from './components/steps/user-info/user-info.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AppointmentCardComponent } from './components/appointment-card/appointment-card.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ToolsComponent } from './components/tools/tools.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCardModule } from 'ng-zorro-antd/card';
import { FormService } from './services/form.service';
import { AzureConnectorService } from './services/azure-connector.service';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { InsuranceOptionsComponent } from './components/steps/insurance-options/insurance-options.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzOverlayModule } from 'ng-zorro-antd/core/overlay';
import { NzProgressModule } from 'ng-zorro-antd/progress';
// import { RouterModule, Routes } from "@angular/router"

// const routes: Routes = [
//   {
//     path: "",
//     component: AgentSchedulerComponent
//   }
// ]

@NgModule({
  exports: [AgentSchedulerComponent],
  declarations: [
    AgentSchedulerComponent,
    AgentComponent,
    AgentsListComponent,
    ConfirmationComponent,
    MeetingComponent,
    RegionComponent,
    TopicComponent,
    UserInfoComponent,
    ProgressBarComponent,
    AppointmentCardComponent,
    ToolsComponent,
    InsuranceOptionsComponent,
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    // RouterModule.forChild(routes),
    NzButtonModule,
    NzCollapseModule,
    NzDatePickerModule,
    FormsModule,
    NzCardModule,
    NzFormModule,
    NzInputModule,
    NzCheckboxModule,
    NzOverlayModule,
    NzSelectModule,
    NzModalModule,
    NzProgressModule,
  ],
  providers: [HttpClientModule, FirestoreConnectorService, FormService, AzureConnectorService],
})
export class AgentSchedulerModule {}
