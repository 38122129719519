<ng-container *ngIf="globalState$ | async as g">
  <ng-container *ngIf="hoosierState$ | async as h">
    <div
      *ngIf="cards && (h.activeCheckoutStep === null || h.activeCheckoutStep === CheckoutStep.VALIDATE)"
      style="display: flex; gap: 16px; flex-direction: column; padding: 20px"
    >
      <ng-container *ngFor="let card of cards">
        <ava-hoosier-account-membership-level-card [card]="card"></ava-hoosier-account-membership-level-card>
      </ng-container>

      <div>
        Road service benefits are available 24 hours after membership purchase. There is a seven-day waiting period for
        Plus and Premier services.
      </div>
    </div>

    <div *ngIf="h.activeCheckoutStep === CheckoutStep.PAYMENT || h.activeCheckoutStep === CheckoutStep.EXECUTE">
      <ng-container *ngIf="membershipStatus$ | async as membershipStatus">
        <form class="ava-column ava-gap-2" style="margin: 0 auto; padding: 20px">
          <div>Summary</div>
          <ng-container *ngIf="membershipStatus === AccountStatus.PENDING">
            <div class="ava-column ava-gap-2">
              <div *ngFor="let summaryItem of h.summary.items" class="ava-row ava-space-between">
                <div>{{ summaryItem.label }}</div>
                <div>{{ summaryItem.amount | currency }}</div>
              </div>
            </div>

            <nz-divider style="margin: 0"></nz-divider>

            <div class="ava-row ava-space-between">
              <div>Total:</div>
              <div>{{ h.summary.balance | currency }}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="membershipStatus !== AccountStatus.PENDING">
            <div class="ava-row ava-space-between">
              <div>{{ h.summary.membershipCodeLabel }} Plan</div>
              <div>{{ h.summary.totalCost | currency }}</div>
            </div>

            <nz-divider style="margin: 0"></nz-divider>

            <div class="ava-row ava-space-between">
              <div>Total:</div>
              <div>{{ h.summary.totalCost | currency }}</div>
            </div>
          </ng-container>

          <!-- AR not part of interface for changeLevelPayload yet -->
          <ava-hoosier-auto-renew
            *ngIf="!h.accountDetails.autoRenew"
            [options]="options.autoRenew"
          ></ava-hoosier-auto-renew>
          <ava-hoosier-payment></ava-hoosier-payment>
          <ava-hoosier-billing-info></ava-hoosier-billing-info>

          <button
            nz-button
            nzType="primary"
            class="ant-btn-bright-blue-to-dark-blue"
            style="flex: 1"
            [disabled]=""
            [nzLoading]="h.VALIDATE_LEVEL_STATUS === OpStatus.RUNNING || !!h.EXECUTE_KEY"
            (click)="changeStep($event, h.activeView, CheckoutStep.EXECUTE, h.VALIDATE_LEVEL_STATUS)"
          >
            Pay {{ (membershipStatus === AccountStatus.PENDING ? h.summary.balance : h.summary.totalCost) | currency }}
            Now
          </button>
        </form>
      </ng-container>
    </div>

    <div *ngIf="h.activeCheckoutStep === CheckoutStep.CONFIRMATION">
      <div style="padding: 20px">
        <div style="font-size: 60px; font-weight: 400">Thank You!</div>
        <div style="font-size: 24px; font-weight: 200; padding-bottom: 16px">
          We're glad you decided to upgrade your Membership.
        </div>
      </div>

      <ava-hoosier-confirmation-details></ava-hoosier-confirmation-details>
    </div>
  </ng-container>
</ng-container>
