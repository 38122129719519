import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { RxState } from '@rx-angular/state';
import {
  AccountMembershipView,
  AccountSection,
  Flow,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  MembershipCard,
  MembershipCardData,
} from '../../hoosier.service';

@Component({
  selector: 'ava-hoosier-membership-card',
  templateUrl: './membership-card.html',
})
export class MembershipCardComponent implements OnInit {
  @Input() membershipCardData: MembershipCardData = {} as MembershipCardData;
  @ViewChild('container') container: ElementRef | undefined;
  membershipCard: MembershipCard | undefined;
  printable: boolean = false;

  constructor(
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private hoosierService: HoosierService,
  ) {}

  ngOnInit(): void {
    this.membershipCard = this.hoosierService.membershipCard(this.membershipCardData.membershipLabel);
    this.printable =
      this.hoosierState.get('activeFlow') === Flow.ACCOUNT &&
      this.hoosierState.get('activeSection') === AccountSection.MEMBERSHIP &&
      this.hoosierState.get('activeView') === AccountMembershipView.CARD_SERVICES;
  }

  printElem(): void {
    if (this.printable) {
      const elem = this.container?.nativeElement;
      const newWindow = window.open('', 'PRINT', 'popup,width=800px,height=400px,top=200');

      newWindow?.document.write('<html><head><title>AAA Hoosier Motor Club</title>');
      newWindow?.document.write('</head><body >');
      newWindow?.document.write('<h1>AAA Hoosier Motor Club</h1>');
      newWindow?.document.write(elem.innerHTML);
      newWindow?.document.write('</body></html>');

      newWindow?.document.close();
      newWindow?.focus();

      newWindow?.print();
      // newWindow.close()
    }
  }
}
