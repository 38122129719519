import { Inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { map } from 'rxjs/operators';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../services/state';
import {
  AccountInformationView,
  CheckoutStep,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  SessionDocResponseObject,
} from '../hoosier.service';
import { combineLatestWith, filter, tap } from 'rxjs';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateChangeAddressEventPayload,
  MembershipConnectSuiteRecostValidateRenewResponse,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { JoinRenewService, OpStatus } from '../../join-renew.service';
import { EventName } from '@aaa/interface-joinRenew-joinRenewLib';
import { ValidationData } from './validate-renew';
import Timestamp = firebase.firestore.Timestamp;
import { MemberInfoAttributes } from '../services/member-info';

export interface ValidateRecostChangeAddressError extends MemberInfoAttributes {
  errorCode: number;
  error: string;
}
export interface ValidateRecostChangeAddressResponse extends MembershipConnectSuiteRecostValidateRenewResponse {
  executionData: string;
  validationData: ValidationData;
}

export interface ValidateChangeAddressResponseObject extends SessionDocResponseObject {
  error: ValidateRecostChangeAddressError;
  response: ValidateRecostChangeAddressResponse;
}

@Injectable({
  providedIn: 'root',
})
export class ValidateRecostChangeAddressService {
  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private hoosierService: HoosierService,
    private joinRenewService: JoinRenewService,
  ) {
    rxEffects.register(this.activeChange$);
    rxEffects.register(this.VALIDATE_KEY$);
    rxEffects.register(this.VALIDATE$);
  }

  activeChange$ = this.hoosierState.select('activeChange').pipe(
    tap(() => {
      const activeView = this.hoosierState.get('activeView');

      if (AccountInformationView.ADDRESS === activeView) {
        const formMembershipPayload = this.hoosierState.get('form').get('membershipPayload')?.value;
        const accountDetails = this.hoosierState.get('accountDetails');
        const membershipRecordChangeAddressPayload: MembershipConnectSuiteRecostValidateChangeAddressEventPayload = {
          method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ADDRESS,
          address: formMembershipPayload.membership.address,
          memberNumber: accountDetails.memberNumber,
        };

        this.hoosierState.set('activeCheckoutStep', () => CheckoutStep.VALIDATE);
        this.validateAddressExecute(membershipRecordChangeAddressPayload);
      }
    }),
  );

  VALIDATE_KEY$ = this.hoosierState.select('VALIDATE_CHANGE_ADDRESS_KEY').pipe(
    combineLatestWith(
      this.hoosierState.select('sessionDoc', 'responses', 'membership', 'connectsuite'),
      this.hoosierState.select('activeView'),
    ),
    map(([key, connectSuite]) => (key ? connectSuite[key] : null)),
    filter((sessionDoc) => !!sessionDoc),
    tap((sessionDoc: SessionDocResponseObject | null) => {
      const validRes = sessionDoc as ValidateChangeAddressResponseObject;

      if (sessionDoc?.meta.isError) {
        this.hoosierState.set('VALIDATE_CHANGE_ADDRESS', () => null);
        this.hoosierState.set('VALIDATE_CHANGE_ADDRESS_ERROR', () => validRes);
        this.hoosierState.set('VALIDATE_CHANGE_ADDRESS_STATUS', () => OpStatus.IS_ERROR);
      } else {
        this.hoosierState.set('VALIDATE_CHANGE_ADDRESS', () => validRes);
        this.hoosierState.set('VALIDATE_CHANGE_ADDRESS_ERROR', () => null);
        this.hoosierState.set('VALIDATE_CHANGE_ADDRESS_STATUS', () => OpStatus.SUCCESS);
      }
    }),
    tap(() => this.hoosierState.set('VALIDATE_CHANGE_ADDRESS_KEY', () => null)),
  );

  VALIDATE$ = this.hoosierState.select('VALIDATE_CHANGE_ADDRESS').pipe(
    filter((val) => !!val),
    tap(() => this.hoosierState.set('activeCheckoutStep', () => CheckoutStep.EXECUTE)),
  );

  validateAddressExecute(payload: MembershipConnectSuiteRecostValidateChangeAddressEventPayload): void {
    const responseKeyTimestamp = Timestamp.now().toMillis().toString();
    this.hoosierState.set('VALIDATE_CHANGE_ADDRESS_KEY', () => responseKeyTimestamp);
    this.hoosierState.set('VALIDATE_CHANGE_ADDRESS_STATUS', () => OpStatus.RUNNING);

    payload.responseKey = responseKeyTimestamp;
    payload.method = MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ADDRESS;

    this.joinRenewService
      .sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, payload)
      .then((response) => {
        if (this.globalState.get('adminUser')) {
          console.log(
            EventName.MEMBERSHIP_QUERY +
              '---' +
              MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ADDRESS +
              '---' +
              responseKeyTimestamp +
              '---' +
              response,
          );
        }
      })
      .catch((error) => {
        this.hoosierState.set('VALIDATE_CHANGE_ADDRESS_STATUS', () => OpStatus.FAILED);
        console.log(error);
      });
  }
}
