import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { PricePreviewActions } from './price-preview.actions';
import { PricePreview } from '../types/types';
import { SaveCacheSettings } from '@aaa/interface-joinRenew-joinRenewLib';
import { getCurrentTimeStamp } from '../utils/get-current-timestamp';
import {
  MembershipMzpMethod,
  MembershipMzpPricePreviewsEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipMzp';
import { filterByClubId } from '../utils/filter-by-club-id';
import { Mzp } from '../mzp.type';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { catchError } from 'rxjs/operators';
import { RequestError, RequestErrorType } from '../generic-errors';
import { getZipcode } from '@aaa/emember/store-membership';

@Injectable()
export class PricePreviewMidstatesEffects {
  store = inject(Store);
  executeService = inject(ExecuteService);
  actions$ = inject(Actions).pipe(filterByClubId(this.store, ClubApp.MidStates));

  public loadPricePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricePreviewActions.load),
      withLatestFrom(this.store.select(getZipcode)),
      switchMap(([{ payload }, zipcode]) => {
        const cacheSettings: SaveCacheSettings = {
          cacheType: 'save',
          context: 'globalContext',
          maxAge: getCurrentTimeStamp(-24 * 3600 * 1000),
        };
        const requestPayload: MembershipMzpPricePreviewsEventPayload = {
          cacheSettings: cacheSettings,
          method: MembershipMzpMethod.PRICE_PREVIEWS,
          zipcode: zipcode,
          promoData: { promoCode: '', couponCode: '', programCode: '' },
        };

        return this.executeService.membershipQuery<Mzp.PricePreviewsResponseObject>(requestPayload).pipe(
          map(({ error, response }) => {
            if (error) {
              return PricePreviewActions.loadFailed({ error: new RequestError(RequestErrorType.Error) });
            }

            const prices: PricePreview[] = [];

            return PricePreviewActions.loadSucceeded({ prices });
          }),
          catchError(() => of(PricePreviewActions.loadFailed({ error: new RequestError(RequestErrorType.Error) }))),
        );
      }),
    ),
  );
}
