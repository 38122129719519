import { Component, inject } from '@angular/core';
import { SwStateMachineService } from '../../services/sw-state-machine.service';

@Component({
  selector: 'ava-smart-weather-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  swSms = inject(SwStateMachineService)
}
