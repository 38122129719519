import { Component, Inject, Input, OnInit } from '@angular/core';
import { combineLatestWith, debounceTime, Observable, of } from 'rxjs';
import { QuillEditor, QuillEditorOptions, QuillEditorStyles } from './service';
import { TokenService } from '../../services/token';
import { DataReadService } from '../../services/data-read';
import { Block } from '../../block/services/block';
import { LinkService, TargetType } from '../../services/link';
import { GLOBAL_RX_STATE, GlobalState, StateService } from '../../services/state';
import { MultiblockService } from '../../services/multiblock';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { tap } from 'rxjs/operators';
import { BlockState } from '../../block/services/state';
import { FieldType } from '../../services/field';
import { Button } from '../button/service';

@Component({
  selector: 'ava-quill-view',
  templateUrl: './view.html',
  providers: [RxState, RxEffects],
})
export class QuillViewComponent implements OnInit {
  @Input() fieldPath: string[] = [];
  @Input() stateId: string | undefined;
  @Input() rowId: string | undefined;
  @Input() options: QuillEditorOptions | undefined;
  @Input() stylesFunction: ((block: Block, width: number, backgroundUrl: boolean) => QuillEditorStyles) | undefined;
  globalState$ = this.globalState.select();
  blockState: RxState<BlockState> | undefined;
  tokenizedValue: string | undefined;
  quillEditorField: QuillEditor | undefined;
  styles: QuillEditorStyles = {};

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private dataReadService: DataReadService,
    private pageService: MultiblockService,
    private stateService: StateService,
    private tokenService: TokenService,
    private linkService: LinkService,
    private rxEffects: RxEffects,
  ) {}

  get styles$(): Observable<any> {
    if (this.blockState) {
      return this.blockState.select('blockValue').pipe(
        combineLatestWith(this.blockState.select('blockWidth')),
        tap(([blockValue, blockWidth]) => {
          if (this.blockState && blockValue && blockWidth !== undefined && typeof this.stylesFunction === 'function') {
            this.styles = this.stylesFunction(blockValue, blockWidth, !!this.blockState.get('background', 'url'));
          }
        }),
      );
    }
    return of(null);
  }

  get tokenized$(): Observable<any> {
    if (this.blockState && this.rowId) {
      return this.blockState.select('blockValue', 'rows', this.rowId).pipe(
        combineLatestWith(this.globalState.select('pageWidth'), this.globalState.select('tokenRefresh')),
        debounceTime(100),
        tap(([field, pageWidth]) => {
          let value = '';
          switch (field.fieldType) {
            case FieldType.QUILL_EDITOR:
            case FieldType.QUILL_EDITOR_ACCORDION:
              value = (field as QuillEditor).value;
              break;
            case FieldType.BUTTON:
              value = (field as Button).value;
              break;
            case FieldType.COMPARISON_CHART: {
              if (this.fieldPath.length) {
                let comparisonChartField: { [key: string]: any } = field;
                for (const fieldName of this.fieldPath) {
                  comparisonChartField = comparisonChartField[fieldName];
                }
                value = (comparisonChartField as QuillEditor).value;
              }
            }
          }
          this.tokenizedValue = this.tokenService.tokenize(
            value,
            pageWidth,
            this.styles?.textAlign,
            this.styles?.color,
          );
        }),
      );
    }
    return of(null);
  }

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId];
    }

    if (this.blockState && this.rowId) {
      this.rxEffects.register(this.styles$);
      this.rxEffects.register(this.tokenized$);
      let field: { [key: string]: any } = this.blockState.get('blockValue', 'rows', this.rowId);
      for (const fieldName of this.fieldPath) {
        field = field[fieldName];
      }
      switch (field?.fieldType) {
        case FieldType.QUILL_EDITOR:
        case FieldType.COMPARISON_CHART:
          this.quillEditorField = field as QuillEditor;
      }
    }
  }

  /**
   * template methods
   */

  enableInlineEdit(event: Event): void {
    event.preventDefault(); // prevents following href
    event.stopPropagation();
    this.globalState.set('selectedField', () => this.stateId + '-' + this.rowId);
    this.pageService.pageClicked();
  }

  /**
   * Loops over the elements as they bubble from the click event upward.
   * Captures the A element if it exists and processes its href as needed.
   * TODO: more functionality can be added besides the href processing
   */
  processLinks(event: MouseEvent): void {
    const eventPath = event.composedPath() as HTMLElement[];
    for (const element of eventPath) {
      // console.log(element)
      if (element.tagName === 'A') {
        event.preventDefault();
        for (const attributeName of element.getAttributeNames()) {
          const attributeValue = element.getAttribute(attributeName);
          // console.log(attributeName)
          // console.log(attributeValue)
          if (attributeName === 'href' && attributeValue) {
            this.linkService.linkClick({ targetOption: TargetType.SELF, targetPath: attributeValue });
          }
        }
        // this.router.navigate([link])
        return;
      }
    }
  }
}
