import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { QuillEditorOptions, QuillEditorStyles } from './service';
import { Block } from '../../block/services/block';
import { StateService } from '../../services/state';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { BlockState } from '../../block/services/state';

@Component({
  selector: 'ava-quill-view-accordion',
  templateUrl: './view-accordion.html',
  providers: [RxState, RxEffects],
})
export class QuillViewAccordionComponent implements OnInit {
  @Input() stateId: string | undefined;
  @Input() rowId: string | undefined;
  @Input() options: QuillEditorOptions | undefined;
  @Input() stylesFunction: ((block: Block, width: number, backgroundUrl: boolean) => QuillEditorStyles) | undefined;
  blockState$: Observable<BlockState> | undefined;
  blockState: RxState<BlockState> | undefined;
  active: boolean | undefined;

  constructor(
    private stateService: StateService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId];
      this.blockState$ = this.blockState?.select();
    }
  }

  /**
   * template methods
   */

  async setActive(): Promise<void> {
    this.elementRef.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    /**
     * wait 600 milliseconds for the previous smooth scroll to finish before closing the other accordions
     */
    // this.active = event // set this rowId
    // await new Promise(r => setTimeout(r, 600))
    // this.active = { [rowId]: event } // unset other rowIds
  }
}
