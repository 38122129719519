import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WINDOW } from '@ng-web-apis/common';
import { Observable } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { Image, ImageService, ImageStyle } from './service';
import { WindowMetaData } from '../../interfaces/window';
import { LinkService } from '../../services/link';
import { StorageService } from '../../services/storage';
import { DataReadService } from '../../services/data-read';
import { BlockState } from '../../block/services/state';
import { GLOBAL_RX_STATE, GlobalState, StateService } from '../../services/state';
import { FormService } from '../../services/form';

interface ComponentState {
  image: Image;
}

@Component({
  selector: 'ava-image-view',
  templateUrl: './view.html',
  providers: [RxState, RxEffects],
})
export class ImageViewComponent implements OnInit {
  @Input() fieldPath: string[] = [];
  @Input() stateId: string | undefined;
  @Input() style: ImageStyle | undefined;
  blockState: RxState<BlockState> | undefined;
  componentState$: Observable<ComponentState> | undefined;
  globalState$ = this.globalState.select();
  blockState$: Observable<BlockState> | undefined;
  formGroup: FormGroup | undefined;

  // @Input() form: FormGroup

  // @Input() blockField: Image
  // @Input() formArrayPath: (string | number)[]
  // @Input() index: number
  // field: Image
  // fieldName: string
  // initialized: boolean

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private dataReadService: DataReadService,
    private imageService: ImageService,
    private stateService: StateService,
    private formService: FormService,
    private storageService: StorageService,
    // private router: Router,
    private componentState: RxState<ComponentState>,
    private linkService: LinkService,
  ) {
    componentState.set({});
  }

  ngOnInit(): void {
    this.style = this.style || {
      borderRadius: undefined,
    };
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId];
      this.formGroup = this.formService.forms[this.blockState.get('block', 'id')];
    }
    if (this.blockState) {
      if (this.fieldPath.length === 3) {
        const array = this.fieldPath as [keyof BlockState, never, never];
        this.componentState.connect('image', this.blockState.select(...array) as Observable<Image>);
      }
      if (this.fieldPath.length === 5) {
        const array = this.fieldPath as [keyof BlockState, never, never, never, never];
        this.componentState.connect('image', this.blockState.select(...array) as Observable<Image>);
      }

      this.blockState$ = this.blockState.select();
      this.componentState$ = this.componentState.select();
    }
  }

  get metaData(): WindowMetaData {
    return this.window['metaData'];
  }

  /**
   * helpers
   */

  getFileUrl(filePath: string): string {
    return this.storageService.getFileUrl(filePath);
  }

  // setSafeUrl(field: Image): void {
  //   this.field = field
  //   this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.storageService.getFileUrl(field.filePath))
  // }

  /**
   * template methods
   */

  imageClick(event: Event): void {
    event.preventDefault();
    this.linkService.linkClick(this.componentState.get('image'));

    /*
        if (!this.globalState.get("adminMode") || this.globalState.get("previewMode")) {

          if (field.targetOption === "new") {
            this.window.open(field.targetPath, "external", "noreferrer")
          }
          if (field.targetOption === "self") {
            if (!this.metaData.appMode) {
              this.window.location.href = field.targetPath
            }
            if (this.metaData.appMode) {
              this.router.navigate(["/" + field.targetPath])
                .catch(() => {
                  //
                })
            }
          }
          if (field.targetOption === "modal") {

          }
        }
    */
  }
}
