<div #container>
  <ng-container *ngIf="!agentCount">No Available Agents</ng-container>

  <ng-container *ngIf="agentCount && sms.state">
    <ava-progress-bar *ngIf="!sms.state.matches('confirmation')" [formId]="formId"></ava-progress-bar>
    <ava-topic *ngIf="sms.state.matches('topic')" [formId]="formId"></ava-topic>
    <ava-region *ngIf="sms.state.matches('region')" [formId]="formId"></ava-region>
    <ava-insurance-options *ngIf="sms.state.matches('insuranceOptions')" [formId]="formId"></ava-insurance-options>
    <ava-meeting *ngIf="sms.state.matches('meeting')" [formId]="formId"></ava-meeting>
    <ava-agents-list
      *ngIf="sms.state.matches('agentsList')"
      [formId]="formId"
      [containerWidth]="containerWidth"
    ></ava-agents-list>
    <ava-agent *ngIf="sms.state.matches('agentPage')" [formId]="formId"></ava-agent>
    <ava-user-info *ngIf="sms.state.matches('userInfo')" [formId]="formId"></ava-user-info>
    <ava-confirmation *ngIf="sms.state.matches('confirmation')" [formId]="formId"></ava-confirmation>
    <br />
  </ng-container>

  <ava-tools [formId]="formId"></ava-tools>
</div>
