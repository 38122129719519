import { Inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { map } from 'rxjs/operators';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../services/state';
import {
  AccountInformationView,
  CheckoutStep,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  SessionDocResponseObject,
} from '../hoosier.service';
import { combineLatestWith, filter, tap } from 'rxjs';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateChangePhoneEventPayload,
  MembershipConnectSuiteRecostValidateChangePhoneResponse,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { JoinRenewService, OpStatus } from '../../join-renew.service';
import { EventName } from '@aaa/interface-joinRenew-joinRenewLib';
import { ValidationData } from './validate-renew';
import Timestamp = firebase.firestore.Timestamp;
import { MemberInfoAttributes } from '../services/member-info';

export interface ValidateChangePhoneError extends MemberInfoAttributes {
  errorCode: number;
  error: string;
}
export interface ValidateChangePhoneResponse extends MembershipConnectSuiteRecostValidateChangePhoneResponse {
  executionData: string;
  validationData: ValidationData;
}
export interface ValidateChangePhoneResponseObject extends SessionDocResponseObject {
  error: ValidateChangePhoneError;
  response: ValidateChangePhoneResponse;
}

@Injectable({
  providedIn: 'root',
})
export class ValidateRecostChangePhoneService {
  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private hoosierService: HoosierService,
    private joinRenewService: JoinRenewService,
  ) {
    rxEffects.register(this.activeChange$);
    rxEffects.register(this.VALIDATE_KEY$);
    rxEffects.register(this.VALIDATE$);
  }

  activeChange$ = this.hoosierState.select('activeChange').pipe(
    filter(() => this.hoosierState.get('activeView') === AccountInformationView.PHONE),
    tap(() => {
      const { cellPhone, homePhone, businessPhone } =
        this.hoosierService.form.get(['membershipPayload', 'primary'])?.value || {};
      const accountDetails = this.hoosierState.get('accountDetails');

      this.hoosierState.set('activeCheckoutStep', () => CheckoutStep.VALIDATE);
      this.validatePhoneExecute({
        method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_PHONE,
        memberNumber: accountDetails.memberNumber,
        businessPhone: businessPhone || null,
        homePhone: homePhone || null,
        cellPhone: cellPhone || null,
      });
    }),
  );

  VALIDATE_KEY$ = this.hoosierState.select('VALIDATE_CHANGE_PHONE_KEY').pipe(
    combineLatestWith(
      this.hoosierState.select('sessionDoc', 'responses', 'membership', 'connectsuite'),
      this.hoosierState.select('activeView'),
    ),
    map(([key, connectSuite]) => (key ? connectSuite[key] : null)),
    filter((response): response is ValidateChangePhoneResponseObject => !!response),
    tap((sessionDoc) => {
      if (sessionDoc?.meta.isError) {
        this.hoosierState.set('VALIDATE_CHANGE_PHONE', () => null);
        this.hoosierState.set('VALIDATE_CHANGE_PHONE_ERROR', () => sessionDoc);
        this.hoosierState.set('VALIDATE_CHANGE_PHONE_STATUS', () => OpStatus.IS_ERROR);
      } else {
        this.hoosierState.set('VALIDATE_CHANGE_PHONE', () => sessionDoc);
        this.hoosierState.set('VALIDATE_CHANGE_PHONE_ERROR', () => null);
        this.hoosierState.set('VALIDATE_CHANGE_PHONE_STATUS', () => OpStatus.SUCCESS);
      }
    }),
    tap(() => this.hoosierState.set('VALIDATE_CHANGE_PHONE_KEY', () => null)),
  );

  VALIDATE$ = this.hoosierState.select('VALIDATE_CHANGE_PHONE').pipe(
    filter((response) => !!response),
    tap(() => this.hoosierState.set('activeCheckoutStep', () => CheckoutStep.EXECUTE)),
  );

  validatePhoneExecute(payload: MembershipConnectSuiteRecostValidateChangePhoneEventPayload): void {
    const responseKeyTimestamp = Timestamp.now().toMillis().toString();

    this.hoosierState.set('VALIDATE_CHANGE_PHONE_KEY', () => responseKeyTimestamp);
    this.hoosierState.set('VALIDATE_CHANGE_PHONE_STATUS', () => OpStatus.RUNNING);

    payload.responseKey = responseKeyTimestamp;

    this.joinRenewService
      .sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, payload)
      .then((response) => {
        if (this.globalState.get('adminUser')) {
          console.log(
            EventName.MEMBERSHIP_QUERY +
              '---' +
              MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ADDRESS +
              '---' +
              responseKeyTimestamp +
              '---' +
              response,
          );
        }
      })
      .catch((error) => {
        this.hoosierState.set('VALIDATE_CHANGE_PHONE_STATUS', () => OpStatus.FAILED);
        console.log(error);
      });
  }
}
