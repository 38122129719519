<div
  style="
    position: relative;
    display: flex;
    gap: 25px;
    align-items: center;
    padding-top: 30px;
    font-size: 24px;
    font-weight: 400;
  "
  [style.color]="styleColor"
>
  How Many Household Drivers?

  <div
    style="
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;
      background-color: white;
      border-radius: 4px;
      color: black;
      padding: 5px;
      border: solid 1px black;
    "
    (click)="$event.stopPropagation()"
  >
    <i nz-icon nzType="minus-circle" nzTheme="outline" style="font-size: 20px"></i>
    <!--    <div style="width: 80px; text-align: center; font-weight: 600">{{ memberQuantity$ | async }}</div>-->
    <div style="width: 80px; text-align: center; font-weight: 600">1</div>
    <i nz-icon nzType="plus-circle" nzTheme="outline" style="font-size: 20px"></i>

    <div
      style="position: absolute; top: 0; left: 0; width: 50%; height: 100%; cursor: pointer"
      (click)="changePrices('decrement')"
    ></div>
    <div
      style="position: absolute; top: 0; right: 0; width: 50%; height: 100%; cursor: pointer"
      (click)="changePrices('increment')"
    ></div>
  </div>
</div>
