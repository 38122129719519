export const JOINCONFIRMATION_MOCK = {
  meta: {
    isError: false,
    operation: 'JOIN',
    resonseKey: '1666764566878',
    method: 'OPERATION_EXECUTE',
    acknowledged: false,
    event: 'OPERATION_EXECUTE',
    updated: 1666764572239,
  },
  error: null,
  response: {
    ok: null,
    response: {
      membership: {
        membershipCredit: {
          attributes: {
            creditAmount: '0.00',
          },
        },
        primaryMember: {
          attributes: {
            membershipNumber: '4290230241657612',
            cellPhone: '1234512345',
            title: '',
            lastName: 'STSTATSR',
            middleIntial: '',
            nameSuffix: '',
            sex: '',
            email: 'tasietna@tsean.com',
            clubJoinDate: '20221026',
            dob: '',
            homePhone: '',
            membershipType: 'P',
            firstName: 'TASATA',
            aaaJoinDate: '20221026',
            businessPhone: '1234512347',
          },
          duesComponent: {
            attributes: {
              pricingType: 'G',
              autoRenew: 'NO',
              requiresCreditCard: 'YES',
              componentAmount: '185.00',
              componentDescription: 'Membership Dues: Tasata Ststatsr',
              componentCode: 'PL',
              componentCurrency: 'USD',
            },
          },
        },
        address: {
          attributes: {
            cityName: 'ASTATS',
            postalCode: '46240',
            address2: '',
            address1: 'ASTAST',
            StateProv: 'IN',
          },
        },
        attributes: {
          renewalStatus: 'R',
          remainingAssociateCount: '0',
          expDate: '20221101',
          membershipStatus: 'P',
          reasonCode: 'B1',
          doNotRenew: 'N',
          promotionalCode: '',
        },
        associateMember: [
          {
            attributes: {
              email: 'emastnn@staest.com',
              lastName: 'ASTATS',
              title: '',
              middleIntial: '',
              firstName: 'TASTATA',
              nameSuffix: '',
              membershipType: 'P',
              relationship: 'OTHER',
              removeAssociate: 'false',
              dob: '',
              sex: '',
              clubJoinDate: '20221026',
              aaaJoinDate: '20221026',
              membershipNumber: '4290230241657620',
            },
            duesComponent: {
              attributes: {
                componentDescription: 'Membership Dues: Tastata Astats',
                componentCurrency: 'USD',
                pricingType: 'G',
                autoRenew: 'NO',
                componentCode: 'PL',
                componentAmount: '90.00',
                requiresCreditCard: 'YES',
              },
            },
          },
          {
            attributes: {
              membershipType: 'P',
              removeAssociate: 'false',
              nameSuffix: '',
              firstName: 'ASTSTATS',
              aaaJoinDate: '20221026',
              lastName: 'ASSTAST',
              middleIntial: '',
              membershipNumber: '4290230241657638',
              relationship: 'OTHER',
              dob: '',
              title: '',
              sex: '',
              clubJoinDate: '20221026',
            },
            duesComponent: {
              attributes: {
                componentDescription: 'Membership Dues: Aststats Asstast',
                requiresCreditCard: 'YES',
                componentCode: 'PL',
                componentAmount: '90.00',
                autoRenew: 'NO',
                componentCurrency: 'USD',
                pricingType: 'G',
              },
            },
          },
        ],
        payment: {
          contract: null,
          transaction: {
            avsMatch: 'Y',
            txnId: '6667645681906645304951',
            code: '00',
            cvvMatch: 'Y',
            approvalCode: '831000',
          },
          account: {
            address: null,
            accountHolder: null,
          },
        },
      },
      attributes: {
        clubId: '0',
        isAutoRenew: 'false',
        waiveEnrollFee: 'false',
        responseCode: '000',
        version: '1.3',
        responseText: 'Success',
      },
    },
    operation: 'JOIN',
  },
};
