import { NgModule } from '@angular/core';
import { DrupalHeaderFooterDirective } from '../directives/drupal-header-footer';
import { DateFormatDirective } from '../directives/date-format';
import { PhoneFormatDirective } from '../directives/phone-format';
import { PhonePipe } from '../pipes/phone.pipe';

@NgModule({
  declarations: [DateFormatDirective, DrupalHeaderFooterDirective, PhoneFormatDirective, PhonePipe],
  imports: [],
  exports: [DateFormatDirective, DrupalHeaderFooterDirective, PhoneFormatDirective, PhonePipe],
  providers: [],
})
export class SharedModule {}
