import { Inject, Injectable } from '@angular/core';
import { CheckboxOptions, InputOptions } from './options-interfaces';
import { Validators } from '@angular/forms';
import { ValidateStatus } from '../../join-renew.service';
import { ValidatorService } from '../../../../services/validator';
import { AccountMembershipView, JoinView, QuickRenewView } from '../hoosier.service';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../services/state';
import { RxState } from '@rx-angular/state';

export interface ChangeLevelOptions {
  autoRenew: CheckboxOptions;
  promoCode: InputOptions;
}

@Injectable({
  providedIn: 'root',
})
export class ChangeLevelFormOptionsService {
  dateOfBirthValidatorPattern = Validators.pattern(
    /^(0[1-9]|1[012])[^0-9]*(0[1-9]|[12][0-9]|3[01])[^0-9]*(19[0-9][0-9]|20[01][0-9]|202[012])$/,
  );

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private validatorService: ValidatorService,
  ) {}

  get options(): ChangeLevelOptions {
    return {
      autoRenew: {
        content: 'Save time by enrolling with Auto Renewal',
        fieldName: 'autoRenew',
        fieldPath: ['changeLevelPayload'],
        validateGroup: [],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
      promoCode: {
        config: {
          autocomplete: '',
          inputGroupSuffix: 'Promo Code',
          inputMode: 'text',
          placeholder: 'Promo Code',
          type: 'text',
        },
        fieldName: 'promoCode',
        fieldPath: ['membershipPayload', 'membership'],
        tip: {
          [ValidateStatus.ERROR]: 'Invalid Promo Code',
          [ValidateStatus.PENDING]: 'verifying the promo code',
        },
        validateGroup: [
          AccountMembershipView.ASSOCIATES_ADD,
          AccountMembershipView.LEVEL,
          AccountMembershipView.RENEW,
          JoinView.PAYMENT,
          QuickRenewView.SUMMARY_AND_PAYMENT,
        ],
        validateStatus: null,
        asyncValidators: [],
        validators: [],
      },
    };
  }
}
