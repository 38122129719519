import { Inject, Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { combineLatestWith, debounceTime, Observable, of, takeWhile, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { WINDOW } from '@ng-web-apis/common';
import { JoinRenewService, OpStatus } from '../../join-renew.service';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../services/state';
import {
  CheckoutStep,
  Flow,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  JoinSection,
  JoinView,
  Section,
  SessionDocResponse,
  SessionDocResponseObject,
  View,
} from '../hoosier.service';
import { FormGroup } from '@angular/forms';
import { PricePreviewsService } from './price-previews';
import { Membership } from '../services/member-info';
import { AlertGroup, AlertMessage, AlertMessageService, AlertType } from '../../../../services/alert-message';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateJoinEventPayload,
  MembershipConnectSuiteRecostValidateJoinResponse,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { EventName } from '@aaa/interface-joinRenew-joinRenewLib';
import Timestamp = firebase.firestore.Timestamp;

export interface ValidateJoinResponseObject extends SessionDocResponseObject {
  error: ValidateJoinError;
  // meta: SessionDocMeta
  response: ValidateJoinResponse;
}

export interface ValidateJoinResponse extends MembershipConnectSuiteRecostValidateJoinResponse {
  validationData: ValidationData;
  executionData: string;
  missingRequirements?: string;
}

export interface ValidateJoinError {
  isAutoRenew: string;
  promotionalCode: string;
  responseCode: string;
  responseText: string;
  totalCost: string;
  version: string;
  waiveEnrollFee: string;
}

export interface ValidationData {
  attributes: {
    isAutoRenew: string;
    promotionalCode: string;
    responseCode: string;
    responseText: string;
    totalCost: string; // useful within validateJoin
    version: string;
    waiveEnrollFee: string;
  };
  membership: ValidationDataMembership;
}

export interface ValidationDataMembership extends Membership {
  costSummary: {
    autoRenewDiscount: string;
    discountAmount: string;
    solicitationDiscount: string;
    solicitationRequiresAutoRenew: string;
    totalCost: string;
    waiveEnrollDiscount: string;
  };
  // membershipOptions: {
  //   duesComponent: ({} | null)[]
  // }
}

@Injectable({
  providedIn: 'root',
})
export class ValidateJoinService {
  form: FormGroup;
  numberOfRetries: number = 0;

  constructor(
    private pricePreviewsService: PricePreviewsService,
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private alertMessageService: AlertMessageService,
  ) {
    this.form = hoosierService.form;
    rxEffects.register(this.VALIDATE_STATUS$);
    rxEffects.register(this.VALIDATE_KEY$);
    rxEffects.register(this.recostValidateJoin_membership$);
    rxEffects.register(this.recostValidateJoin_payment_autoRenew$);
    rxEffects.register(this.activeChange$);
    rxEffects.register(this.retryOnError$);
  }

  activeChange$ = this.hoosierState.select('activeChange').pipe(
    combineLatestWith(
      this.hoosierState.select('activeFlow'),
      this.hoosierState.select('activeSection'),
      this.hoosierState.select('activeCheckoutStep'),
    ),
    takeWhile(() => this.numberOfRetries < 3),
    debounceTime(10000),
    tap(
      ([activeChange, activeFlow, activeSection, activeCheckoutStep]: [
        number | null,
        Flow | null,
        Section | null,
        CheckoutStep | null,
      ]) => {
        if (
          activeChange &&
          (activeSection === JoinSection.MEMBER_INFO || activeSection === JoinSection.DONOR_INFO) &&
          activeCheckoutStep === CheckoutStep.VALIDATE
        ) {
          switch (activeFlow) {
            case Flow.JOIN:
            case Flow.GIFT: {
              const membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload =
                this.form.get('membershipPayload')?.value;
              this.recostValidateJoin(membershipPayload, true, true);
            }
          }
        }
      },
    ),
  );

  get recostValidateJoin_membership$(): Observable<any> {
    const membershipLevelForm = this.form.get(['membershipPayload', 'membership', 'membershipLevel']);
    const associateCountForm = this.form.get(['membershipPayload', 'membership', 'associateCount']);
    if (membershipLevelForm && associateCountForm) {
      return membershipLevelForm.valueChanges.pipe(
        combineLatestWith(associateCountForm.valueChanges),
        debounceTime(10),
        tap(([membershipLevel, associateCount]) => {
          const membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload =
            this.form.get('membershipPayload')?.value;
          switch (this.hoosierState.get('activeFlow')) {
            case Flow.JOIN:
            case Flow.GIFT:
              switch (this.hoosierState.get('activeSection')) {
                case JoinSection.MEMBER_INFO:
                case JoinSection.PAYMENT:
                  membershipPayload.membership.membershipLevel = membershipLevel; // we may not need these with debounceTime in place
                  membershipPayload.membership.associateCount = associateCount;
                  if (this.hoosierState.get('VALIDATE_JOIN_STATUS') === OpStatus.SUCCESS) {
                    this.hoosierState.set('VALIDATE_JOIN_STATUS', () => OpStatus.STOPPED);
                  }
                  this.recostValidateJoin(membershipPayload);
              }
          }
        }),
      );
    }
    return of(null);
  }

  get recostValidateJoin_memberInfo$(): Observable<any> {
    const addressForm = this.form.get(['membershipPayload', 'membership', 'address']);
    const firstNameForm = this.form.get(['membershipPayload', 'primary', 'firstName']);
    const lastNameForm = this.form.get(['membershipPayload', 'primary', 'lastName']);
    const rvForm = this.form.get(['membershipPayload', 'membership', 'rv']);
    const autoForm = this.form.get(['membershipPayload', 'membership', 'autoRenew']);
    if (addressForm && firstNameForm && lastNameForm && rvForm && autoForm) {
      return this.hoosierState.select('formStatus').pipe(
        combineLatestWith(
          addressForm.valueChanges,
          firstNameForm.valueChanges,
          lastNameForm.valueChanges,
          rvForm.valueChanges,
          autoForm.valueChanges,
        ),
        debounceTime(1000), // debounce is required, or else we get previous autoRenew value and possible others
        tap(() => {
          const formGroup = this.form.get('membershipPayload') as FormGroup;
          const membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload = formGroup.getRawValue();
          switch (this.hoosierState.get('activeFlow')) {
            case Flow.JOIN:
            case Flow.GIFT:
              switch (this.hoosierState.get('activeSection')) {
                case JoinSection.MEMBER_INFO:
                case JoinSection.PAYMENT:
                  this.recostValidateJoin(membershipPayload);
              }
          }
        }),
      );
    }
    return of(null);
  }

  get recostValidateJoin_payment_autoRenew$(): Observable<any> {
    const autoRenewForm = this.form.get(['membershipPayload', 'membership', 'autoRenew']);
    const debounceTimeDelay = this.globalState.get('adminUser') ? 1 : 1000; // 1 allows spamming the autoRenew button for load testing
    if (autoRenewForm) {
      return autoRenewForm.valueChanges.pipe(
        debounceTime(debounceTimeDelay),
        tap(() => {
          switch (this.hoosierState.get('activeFlow')) {
            case Flow.JOIN:
            case Flow.GIFT: {
              const membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload = (
                this.form.get('membershipPayload') as FormGroup
              ).getRawValue();

              if (
                this.hoosierState.get('activeView') === JoinView.PAYMENT &&
                this.form.get('membershipPayload')?.valid
              ) {
                if (this.hoosierState.get('VALIDATE_JOIN_STATUS') === OpStatus.SUCCESS) {
                  this.hoosierState.set('VALIDATE_JOIN_STATUS', () => OpStatus.STOPPED);
                }
                this.recostValidateJoin(membershipPayload, this.globalState.get('adminUser')); // force=true allows spamming the autoRenew button for load testing
              }
            }
          }
        }),
      );
    }
    return of(null);
  }

  retryOnError$ = this.hoosierState.select('VALIDATE_JOIN_STATUS').pipe(
    tap((status) => {
      if (status === OpStatus.IS_ERROR) {
        this.alertMessageService.set(AlertMessage.ERROR_RETRY, AlertType.INFO);
        // this.recostValidateJoin(this.form.get("membershipPayload").value, true)
      }
    }),
  );

  VALIDATE_KEY$ = this.hoosierState.select('VALIDATE_JOIN_KEY').pipe(
    combineLatestWith(this.hoosierState.select('sessionDoc', 'responses', 'membership', 'connectsuite')),
    tap(([VALIDATE_JOIN_KEY, connectsuite]: [string | null, SessionDocResponse]) => {
      if (VALIDATE_JOIN_KEY && connectsuite[VALIDATE_JOIN_KEY]) {
        this.hoosierState.set('VALIDATE_JOIN_KEY', () => null);

        const responseTime = Timestamp.now().toMillis() - parseInt(VALIDATE_JOIN_KEY);
        if (this.globalState.get('adminUser')) {
          console.log(responseTime, 'milliseconds - VALIDATE_JOIN');
        }
        const membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload = (
          this.form.get('membershipPayload') as FormGroup
        ).getRawValue();

        const validateResponseObject = connectsuite[VALIDATE_JOIN_KEY] as ValidateJoinResponseObject;
        const isError: boolean = validateResponseObject.meta.isError;

        if (!isError) {
          this.alertMessageService.clearAll(AlertGroup.ERROR);

          const costsAttributes = validateResponseObject.response.validationData.attributes;
          this.hoosierState.set('VALIDATE_JOIN', () => validateResponseObject);
          this.hoosierState.set('VALIDATE_JOIN_ERROR', () => null);
          this.hoosierState.set('VALIDATE_JOIN_STATUS', () => OpStatus.SUCCESS);
          if (this.hoosierState.get('PROMO_CODE_STATUS') === OpStatus.RUNNING) {
            this.hoosierState.set('PROMO_CODE_STATUS', () => OpStatus.SUCCESS);
          }

          /**
           * copy totalCost into paymentPayload
           */
          this.form
            .get(['paymentPayload', 'executionData', 'amountDetails', 'totalAmount'])
            ?.setValue(costsAttributes.totalCost);

          /**
           * Save promotionalCode from response since the recost was successful
           */
          if (
            costsAttributes.promotionalCode === membershipPayload.membership.promoCode ||
            (costsAttributes.promotionalCode === undefined && membershipPayload.membership.promoCode === '')
          ) {
            this.hoosierState.set('VALIDATE_JOIN_STATUS', () => OpStatus.SUCCESS);
            this.globalState.set('userSession', (oldState) => {
              oldState.userSession.promoCode = membershipPayload.membership.promoCode || false;
              return oldState.userSession;
            });
          }

          const costSummary = validateResponseObject.response.validationData.membership.costSummary;
          if (costSummary) {
            this.hoosierState.set('summary', (oldState) => {
              oldState.summary.totalCost = parseFloat(costSummary.totalCost);
              oldState.summary.autoRenewDiscount = parseFloat(costSummary.autoRenewDiscount);
              oldState.summary.promotionalCode =
                validateResponseObject.response.validationData.attributes.promotionalCode;
              oldState.summary.solicitationDiscount = parseFloat(costSummary.solicitationDiscount);
              return oldState.summary;
            });
          }
        }

        if (isError) {
          this.hoosierState.set('VALIDATE_JOIN', () => null);
          this.hoosierState.set('VALIDATE_JOIN_ERROR', () => validateResponseObject);
          this.hoosierState.set('VALIDATE_JOIN_STATUS', () => OpStatus.IS_ERROR);
          switch (validateResponseObject.error.responseCode) {
            case '040': // Membership already exists and is in the Active status
            case '090': // Membership already exists
            case '091': // Membership already exists.
            case '092': // Membership already exists.
              break;
            case '012': // invalid promoCode
              this.hoosierState.set('PROMO_CODE_STATUS', () => OpStatus.IS_ERROR);

              /**
               * set userSession.promoCode to false
               */
              this.globalState.set('userSession', (oldState) => {
                oldState.userSession.promoCode = false;
                return oldState.userSession;
              });

              /**
               * re-run recost without promoCode
               */
              // console.log("re-run recost without promoCode")
              membershipPayload.membership.promoCode = '';
              this.recostValidateJoin(membershipPayload, true);

              break;
            case '033': // Outside age limit range
            default:
              this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR);
          }
        }
      }
    }),
  );

  VALIDATE_STATUS$ = this.hoosierState.select('VALIDATE_JOIN_STATUS').pipe(
    tap((status) => {
      if (status === OpStatus.RUNNING) {
        // this.hoosierService.form
        //   .get(["membershipPayload", "membership", "promoCode"])
        //   .markAsPending()
      }
      if (status === OpStatus.SUCCESS) {
        if (this.hoosierState.get('activeCheckoutStep') === CheckoutStep.VALIDATE) {
          this.hoosierState.set('activeChange', () => null);
          this.hoosierState.set('activeCheckoutStep', () => null);
          this.hoosierState.set('activeView', () => this.hoosierState.get('activeDestination') as View);
          this.hoosierState.set('activeSection', () => this.hoosierState.get('activeDestination') as JoinSection);
        }

        // const RECOST_VALIDATE_JOIN: SessionDocResponse = this.hoosierState.get("RECOST_VALIDATE_JOIN")
        // if (VALIDATE_SUMMARY.response.costs.attributes.promotionalCode) {
        this.form.get(['membershipPayload', 'membership', 'promoCode'])?.setErrors(null, { emitEvent: false });
        // }
      }
    }),
  );

  validatePromoCode() {
    const membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload = (
      this.form.get('membershipPayload') as FormGroup
    ).getRawValue();
    switch (this.hoosierState.get('activeFlow')) {
      case Flow.JOIN:
      case Flow.GIFT:
        switch (this.hoosierState.get('activeSection')) {
          case JoinSection.PAYMENT:
            if (this.hoosierState.get('VALIDATE_JOIN_STATUS') === OpStatus.SUCCESS) {
              this.hoosierState.set('VALIDATE_JOIN_STATUS', () => OpStatus.STOPPED);
            }
            this.hoosierState.set('PROMO_CODE_STATUS', () => OpStatus.RUNNING);
            this.recostValidateJoin(membershipPayload);
        }
    }
  }

  recostValidateJoin(
    membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload,
    force?: boolean,
    retry?: boolean,
  ): void {
    if (
      this.hoosierState.get('VALIDATE_JOIN_STATUS') === OpStatus.STOPPED ||
      force
      // || this.readyToCheckForDuplicateMember()
    ) {
      if (retry) {
        this.numberOfRetries++;
      }
      if (!retry) {
        this.numberOfRetries = 0;
      }
      const responseKeyTimestampString = Timestamp.now().toMillis().toString();
      this.hoosierState.set('VALIDATE_JOIN_KEY', () => responseKeyTimestampString);
      this.hoosierState.set('VALIDATE_JOIN_STATUS', () => OpStatus.RUNNING);

      // console.log("reCosting")

      switch (this.hoosierState.get('activeFlow')) {
        case Flow.JOIN:
          if (membershipPayload.donorMembership) {
            delete membershipPayload.donorMembership;
          }
          break;
      }

      const primaryDob = membershipPayload.primary?.dob;
      if (membershipPayload.primary?.dob.length === 10) {
        const primaryDob = membershipPayload.primary.dob;
        membershipPayload.primary.dob = primaryDob.slice(6, 10) + primaryDob.slice(0, 2) + primaryDob.slice(3, 5);
      }
      if (membershipPayload.associates) {
        for (const [index, associate] of membershipPayload.associates.entries()) {
          const associateDob = associate.dob;
          if (associateDob?.length === 10) {
            membershipPayload.associates[index].dob =
              associateDob.slice(6, 10) + associateDob.slice(0, 2) + associateDob.slice(3, 5);
          }
        }
      }

      membershipPayload.membership.address.postalCode =
        membershipPayload.membership.address.postalCode || this.globalState.get('userSession', 'zipcode');
      membershipPayload.responseKey = responseKeyTimestampString;
      membershipPayload.method = MembershipConnectSuiteMethod.RECOST_VALIDATE_JOIN;

      if (this.globalState.get('adminUser')) {
        // console.log(membershipPayload)
      }
      this.joinRenewService
        .sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, membershipPayload)
        .then((response) => {
          if (this.globalState.get('adminUser')) {
            console.log(
              EventName.MEMBERSHIP_QUERY +
                '---' +
                MembershipConnectSuiteMethod.RECOST_VALIDATE_JOIN +
                '---' +
                responseKeyTimestampString +
                '---' +
                response,
            );
          }
        })
        .catch((error) => {
          this.hoosierState.set('VALIDATE_JOIN_STATUS', () => OpStatus.FAILED);
          // console.log(error)
        });
    }
  }

  readyToCheckForDuplicateMember(): boolean {
    return !!(
      this.form.get(['membershipPayload', 'membership', 'membershipLevel'])?.valid &&
      this.form.get(['membershipPayload', 'membership', 'address', 'address1'])?.value &&
      this.form.get(['membershipPayload', 'membership', 'address', 'cityName'])?.value &&
      this.form.get(['membershipPayload', 'membership', 'address', 'postalCode'])?.value.length === 5 &&
      this.form.get(['membershipPayload', 'primary', 'firstName'])?.value &&
      this.form.get(['membershipPayload', 'primary', 'lastName'])?.value
    );
  }
}
