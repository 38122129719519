import { Component, Inject, Input, OnInit } from '@angular/core';
import { filter, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { ImageStyle } from '../../../../../fields/image/service';
import { PriceChartCard, PriceChartCardService } from './service';
import { GLOBAL_RX_STATE, GlobalState, StateService } from '../../../../../services/state';
import { Link, TargetType } from '../../../../../services/link';
import { HOOSIER_RX_STATE, HoosierState, JoinSection } from '../../hoosier.service';
import { BlockState } from '../../../../../block/services/state';
import { FieldType } from '../../../../../services/field';
import { Store } from '@ngrx/store';
import { AppStore } from '@aaa/emember/store-types';
import { getPreviewPriceLevelPriceUpgrade } from '@aaa/emember/store-price-preview';

@Component({
  selector: 'ava-price-chart-card-view',
  templateUrl: './view.html',
  providers: [RxState, RxEffects],
})
export class PriceChartCardViewComponent implements OnInit {
  @Input() fieldPath: string[] = [];
  @Input() stateId: string | undefined;
  @Input() rowId: string | undefined;
  @Input() typeCTA: boolean | undefined;
  @Input() typeCard: boolean | undefined;
  hoosierState$ = this.hoosierState.select();
  blockState: RxState<BlockState> | undefined;
  priceChartCard: PriceChartCard | undefined;
  imageStyle: ImageStyle = { borderRadius: '4px' };
  price: number | undefined;
  priceChartCardLink: Link | undefined;
  backgroundColors = {
    PREMIER: '#0C2B53',
    PLUS: '#123d74',
    CLASSIC: '#25538F',
  };
  JoinSection = JoinSection;

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private stateService: StateService,
    public priceChartCardService: PriceChartCardService,
    private store: Store<AppStore>,
  ) {}

  ngOnInit(): void {
    this.initStateId();
    this.initBlockState();
    this.initPricePriceLevel();
  }

  buildPriceChartCard(field: any) {
    this.priceChartCard = field as PriceChartCard;
    this.price = this.priceChartCard.description.price;

    const { selectedLevel, selectedRv } = this.priceChartCard.membership;
    const targetPath = `${this.globalState.get('location', 'pathname')}?membershipLevel=${selectedLevel}&rv=${
      selectedRv ? 'true' : false
    }`;
    this.priceChartCardLink = { targetPath, targetOption: TargetType.SELF };
  }

  linkClick(event: Event): void {
    event.preventDefault();
    if (this.priceChartCard?.link?.targetPath) {
      this.priceChartCardService.priceChartCardLink = this.priceChartCard.link;
    }
    if (!this.priceChartCard?.link?.targetPath && this.priceChartCardLink) {
      this.priceChartCardService.priceChartCardLink = this.priceChartCardLink;
    }
    this.priceChartCardService.cardClicked = this.priceChartCard?.membership.selectedLevel;
    this.hoosierState.set('activeDestination', () => JoinSection.RV_COVERAGE);
  }

  private initStateId() {
    if (this.stateId) {
      this.blockState = this.stateService.states[this.stateId];
    }
  }

  private initBlockState() {
    if (this.blockState && this.rowId) {
      let field: { [key: string]: any } = this.blockState.get('blockValue', 'rows', this.rowId);
      if (field.fieldType === FieldType.COMPARISON_CHART) {
        for (const fieldName of this.fieldPath) {
          field = field[fieldName];
        }
      }
      if (field.fieldType === FieldType.PRICE_CHART_CARD) {
        this.buildPriceChartCard(field);
      }
    }
  }

  private initPricePriceLevel() {
    if (this.priceChartCard) {
      this.rxEffects.register(
        this.store
          .select(
            getPreviewPriceLevelPriceUpgrade({
              level: this.priceChartCard.membership.selectedLevel,
              rv: this.priceChartCard.membership.selectedRv,
            }),
          )
          .pipe(
            filter((price) => !!price),
            tap((price) => (this.price = price)),
          ),
      );
    }
  }
}
