import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, AfterViewInit, Input } from '@angular/core';
import { StateMachineService } from '../../services/state-machine.service';
import { Subscription } from 'rxjs';
import { FormService } from '../../services/form.service';
import { FormControl } from '@angular/forms';
import { FormValues, Step } from '@aaa/interface-agentScheduler';

@Component({
  selector: 'ava-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() formId: string | undefined;
  formValues: FormValues | undefined;
  @ViewChild('container') container: ElementRef | undefined;
  containerWidth: number | undefined;
  title: string = '';
  stateMatches: string = '';
  stepsNormalFlow = [
    {
      title: 'Type of Appointment',
      percent: 25,
      step: 0,
      states: ['topic', 'region', 'meeting'],
      event: 'JUMP_TYPE',
    },
    {
      title: 'Choose an Agent',
      percent: 50,
      step: 1,
      states: ['agentsList'],
      event: 'JUMP_AGENT',
    },
    {
      title: 'Book a Time',
      percent: 75,
      step: 2,
      states: ['agentPage'],
      event: 'JUMP_TIME',
    },
    {
      title: 'Complete Appointment',
      percent: 100,
      step: 3,
      states: ['userInfo', 'confirmation'],
    },
  ];

  stepsAgentFlow = [
    {
      title: 'Choose an Agent',
      percent: 25,
      step: 0,
      states: ['agentsList'],
      event: 'JUMP_AGENT',
    },
    {
      title: 'Book a Time',
      percent: 50,
      step: 1,
      states: ['agentPage'],
      event: 'JUMP_TIME',
    },
    {
      title: 'Type of Appointment',
      percent: 75,
      step: 2,
      states: ['topic', 'region', 'meeting'],
      event: 'JUMP_TYPE',
    },
    {
      title: 'Complete Appointment',
      percent: 100,
      step: 3,
      states: ['userInfo', 'confirmation'],
    },
  ];

  //controller
  steps: Step[] = [];
  stateSub: Subscription | undefined;
  agentFlowSub: Subscription | undefined;

  //template control
  progressValue: number = 0;
  progressStep: number = 0;
  showBackLink: boolean = false;
  agent: FormControl | undefined;

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {}

  ngOnInit(): void {
    if (this.formId) {
      this.formValues = this.formService.formValues[this.formId];
      this.agent = this.formService.form[this.formId]?.get('agent') as FormControl;
    }
    /**
     * In conjunction with this.onPopstate(), we enable moving back in the flow with the browser back button.
     */
    history.pushState(null, document.title, location.href.toLowerCase());

    this.agentFlowSub = this.sms.agentFlowSubject.subscribe((agentFlow: boolean) => {
      this.steps = agentFlow ? this.stepsAgentFlow : this.stepsNormalFlow;
    });

    this.stateSub = this.sms.stateSubject.subscribe((state: any) => {
      if (state && this.steps) {
        if (state.matches('topic')) {
          this.stateMatches = 'topic';
          this.title = 'Book an Appointment';
        }
        if (state.matches('region')) {
          this.stateMatches = 'region';
          this.title = 'Book an Appointment';
        }
        if (state.matches('meeting')) {
          this.stateMatches = 'meeting';
          this.title = 'Book an Appointment';
        }
        if (state.matches('agentsList')) {
          this.stateMatches = 'agentsList';
          this.title = 'Choose a Travel Agent';
        }
        if (state.matches('agentPage')) {
          this.stateMatches = 'agentPage';
          if (this.agent?.value.fName) {
            this.title = 'Book with ' + this.agent.value.fName;
          } else {
            this.title = '';
          }
        }
        if (state.matches('userInfo')) {
          this.stateMatches = 'userInfo';
          this.title = 'Complete Appointment';
        }

        //progress value
        this.steps.forEach((step) => {
          step.states.forEach((stateName) => {
            if (state.matches(stateName)) {
              this.progressValue = step.percent;
              this.progressStep = step.step;
            }
          });
        });

        /**
         * Agent flow:
         * - hide backLink on book time step and agentsList step
         *
         * Topic flow:
         * - show backLink an all steps other than topic
         * - hide backLink on topic step only
         */
        this.showBackLink =
          (!this.formValues?.overrides.agentEmail && !state.matches('topic')) ||
          (this.formValues?.overrides.agentEmail &&
            (state.matches('topic') ||
              state.matches('region') ||
              state.matches('insuranceOptions') ||
              state.matches('meeting') ||
              state.matches('userInfo')));
      }
    });
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.agentFlowSub?.unsubscribe();
    this.stateSub?.unsubscribe();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.containerWidth = this.container?.nativeElement.offsetWidth;
  }

  jumpToStep(step: Step, progressValue: number): void {
    if (step.percent > progressValue) return;
    if (step.event) {
      this.sms.sendEvent(step.event);
    }
  }

  @HostListener('window:popstate')
  onPopstate(): void {
    if (this.showBackLink) {
      history.pushState(null, document.title, location.href.toLowerCase());
      this.sms.sendEvent('PREVIOUS');
    }
  }
}
