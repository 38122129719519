<div #container>
  <div
    #mobileContainer
    *ngIf="containerWidth && containerWidth < 760"
    style="display: flex; justify-content: center; padding: 10px"
  >
    <a
      (click)="sms.sendEvent('PREVIOUS'); $event.preventDefault()"
      class="aaa-link-color"
      style="display: flex; justify-content: center"
      *ngIf="showBackLink"
    >
      <i nz-icon style="padding-left: 5px; display: flex; align-items: center">
        <svg
          style="width: 15px; height: 15px"
          class="aaa-link-fill"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <title>left arrow</title>
          <path d="M388.262 511.63h-104.72l-160.036-255.694 160.036-255.565h104.72l-159.14 256.104z"></path>
        </svg>
      </i>
    </a>
    <div [ngStyle]="{ padding: showBackLink ? '0 20px 0 5px' : '0 5px' }">
      {{ title }}
    </div>
  </div>

  <div #desktopContainer *ngIf="containerWidth && containerWidth >= 760">
    <div style="display: flex">
      <div
        *ngFor="let step of steps"
        style="display: flex; justify-content: center; padding: 20px 0"
        [ngStyle]="{ width: '25%' }"
        [ngClass]="{
          'aaa-link-color': progressValue >= step.percent,
          clickable: progressStep > step.step || (step.step === 0 && stateMatches !== 'topic'),
        }"
        (click)="jumpToStep(step, progressValue)"
      >
        {{ step.title }}
        <i nz-icon style="padding-left: 5px; display: flex; align-items: center">
          <svg
            class="aaa-link-fill"
            style="color:; width: 15px; height: 15px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <title>right arrow</title>
            <path d="M123.737 511.63h104.72l160.036-255.694-160.036-255.565h-104.72l159.14 256.104z"></path>
          </svg>
        </i>
      </div>
    </div>

    <nz-progress [nzPercent]="progressValue" [nzShowInfo]="false" nzStrokeColor="#00a0df"></nz-progress>
    <!--    <mat-progress-bar [style]="'margin-bottom: 10px'" mode="determinate" [value]="progressValue"></mat-progress-bar>-->

    <div style="display: flex; justify-content: center">
      <a
        (click)="sms.sendEvent('PREVIOUS'); $event.preventDefault()"
        style="font-weight: 500"
        [ngStyle]="{ opacity: showBackLink ? 1 : 0, 'pointer-events': showBackLink ? 'initial' : 'none' }"
      >
        < Back
      </a>
    </div>
  </div>
</div>
