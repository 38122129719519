<div #container style="display: flex; flex-direction: column; align-items: center">
  <div
    style="display: grid; align-items: flex-start; justify-content: space-between; padding-bottom: 20px"
    [ngStyle]="gridTemplateColumns"
  >
    <div style="display: grid; grid-template-columns: 160px 160px; grid-gap: 0 10px">
      <div>Vehicle:</div>
      <div style="font-weight: 400">
        {{ bs.vehicle }}
      </div>
      <div>Engine Size:</div>
      <div>
        {{ bs.engineSize }}
      </div>
      <div>Part Number:</div>
      <div>
        {{ bs.partNumber }}
      </div>
    </div>
    <div style="display: grid; grid-template-columns: 160px 160px; grid-gap: 0 10px">
      <ng-container *ngFor="let text of included">
        <div>{{ text }}:</div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 460" style="height: 16px; fill: #5cb85c">
            <title>checkmark</title>
            <path d="M432 64l-240 240-112-112-80 80 192 192 320-320z"></path>
          </svg>
          Included!
        </div>
      </ng-container>
    </div>
  </div>
  <div>
    Non-member Price:
    {{ bs.priceNonMember | currency }}*
  </div>
  <div [style]="'padding-bottom: 20px; font-size: 36px; font-weight: 400;'">
    AAA Member Price:
    {{ bs.priceMember | currency }}*
  </div>
</div>
<div style="display: flex; justify-content: center">
  <button nz-button nzType="primary" style="margin: 10px">
    <a target="_blank" [href]="bs.requestButtonUrl">Request Online</a>
  </button>
  <button
    nz-button
    nzType="primary"
    (click)="bs.setComponentState('buttonClicked', this.bs.ComponentState.FORM, $event)"
    style="margin: 10px"
  >
    New quote
  </button>
</div>
<div style="text-align: center; padding-bottom: 20px">
  <div style="font-weight: 500; display: inline-block">Or Call</div>
  <a href="tel:1-800-222-4357">1-800-222-4357</a>
  to have your battery delivered and installed.
</div>
<div style="text-align: center; padding-bottom: 20px">
  *Price does not include tax and environmental fee. Certain vehicles, due to the location of the battery, may require
  both additional time and labor cost to install.
</div>
