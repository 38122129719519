<ng-container *ngIf="globalState$ | async as g">
  <ng-container *ngIf="blockState$ | async as b">
    <ng-container *ngIf="templateRef && (rowVisible || g.adminMode)">
      <div
        *ngIf="b.blockValue.rows[rowId] && (!b.backgroundImageRows[rowIndex] || columnIndex !== 0)"
        style="position: relative"
        [style.width]="
          b.blockValue.options.alignment === 'center' || b.blockValue.rows[rowId]?.fieldType === FieldType.JAVASCRIPT
            ? '100%'
            : undefined
        "
        [style.box-shadow]="
          highlight[stateId + '-' + columnIndex.toString() + '-' + rowIndex.toString()] ? border.boxShadow : undefined
        "
        [style.border]="border.border"
        [style.border-radius.px]="
          [stateId + '-' + columnIndex.toString() + '-' + rowIndex.toString()] ? border.borderRadiusPx : undefined
        "
        [style.outline]="border.outline"
        [style.padding.px]="
          highlight[stateId + '-' + columnIndex.toString() + '-'] ||
          highlight[stateId + '-' + columnIndex.toString() + '-' + rowIndex.toString()]
            ? undefined
            : undefined
        "
      >
        <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
      </div>
    </ng-container>
    <ng-template #JOIN_RENEW_CONFIRMATION_SUCCESS>
      <ava-confirmation-join-view [stateId]="stateId" [rowId]="rowId"></ava-confirmation-join-view>
    </ng-template>

    <ng-template #PRICE_CHART_MEMBER_QUANTITY>
      <ava-price-chart-member-quantity-view [stateId]="stateId" [rowId]="rowId"></ava-price-chart-member-quantity-view>
    </ng-template>

    <ng-template #PRICE_CHART_CARD>
      <ava-price-chart-card-view [stateId]="stateId" [rowId]="rowId" [typeCard]="true"></ava-price-chart-card-view>
    </ng-template>

    <ng-template #QUILL_EDITOR>
      <ava-quill-view
        [stateId]="stateId"
        [rowId]="rowId"
        [options]="quillEditorOptions"
        [stylesFunction]="styles.textArea"
      ></ava-quill-view>
    </ng-template>

    <ng-template #QUILL_EDITOR_ACCORDION>
      <ava-quill-view-accordion
        [stateId]="stateId"
        [rowId]="rowId"
        [options]="quillEditorOptions"
        [stylesFunction]="styles.textArea"
        style="scroll-margin-top: 200px"
      ></ava-quill-view-accordion>
    </ng-template>

    <ng-template #BATTERY_QUOTER>
      <div style="width: 100%">
        <ava-battery-quoter></ava-battery-quoter>
      </div>
    </ng-template>

    <ng-template #SMART_WEATHER>
      <div style="width: 100%">
        <ava-smart-weather></ava-smart-weather>
      </div>
    </ng-template>

    <ng-template #AGENT_SCHEDULER>
      <div style="width: 100%">
        <ava-agent-scheduler [params]="agentSchedulerParams"></ava-agent-scheduler>
      </div>
    </ng-template>

    <ng-template #BUTTON>
      <div style="width: 100%" [style.text-align]="b.blockValue.options.alignment">
        <ava-button-view
          [stateId]="stateId"
          [columnIndex]="columnIndex"
          [rowId]="rowId"
          [options]="options.button"
        ></ava-button-view>
      </div>
    </ng-template>

    <ng-template #VIDEO>
      <ava-video-view [rowId]="rowId" [options]="options.video" [stateId]="stateId"></ava-video-view>
    </ng-template>

    <ng-template #JAVASCRIPT>
      <ava-javascript-view
        [rowId]="rowId"
        [options]="options.javascript"
        [stylesFunction]="styles.javascript"
      ></ava-javascript-view>
    </ng-template>

    <ng-template #IMAGE>
      <ava-image-view [stateId]="stateId" [fieldPath]="['blockValue', 'rows', rowId.toString()]"></ava-image-view>
    </ng-template>

    <ng-template #MULTI_BLOCK>
      <ava-block-state-wrapper
        [blockId]="b.blockValue.rows[rowId]['blockId']"
        [parentStateId]="stateId"
        [parentColumnIndex]="columnIndex"
        [parentRowId]="rowId"
        [parentRowIndex]="rowIndex"
        [stateId]="rowId"
      ></ava-block-state-wrapper>
    </ng-template>

    <ng-template #COMPARISON_CHART>
      <ava-comparison-chart-view [stateId]="stateId" [rowId]="rowId"></ava-comparison-chart-view>
    </ng-template>
  </ng-container>
</ng-container>
