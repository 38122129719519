import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import {
  Agent,
  AgentTimeSlot,
  AppMode,
  CFMObj,
  FormValues,
  Meeting,
  Overrides,
  Params,
  Region,
  Topic,
} from '@aaa/interface-agentScheduler';
import { ValidatorService } from '../../../services/validator';

export interface FormValue {
  agent: Agent | undefined;
  agentEmail: string;
  agents: Agent[];
  agentTimezone: string;
  comment: string;
  date: string;
  departure: string[];
  email: string;
  firstName: string;
  lastName: string;
  meetings: Meeting[];
  phone: string;
  regions: Region[];
  timeSlot: AgentTimeSlot | undefined;
  topics: Topic[];
}

@Injectable({
  providedIn: 'root',
})
export class FormService {
  form: {
    [key: string]: FormGroup;
  } = {};
  formValues: {
    [key: string]: FormValues;
  } = {};

  constructor(private validatorService: ValidatorService) {}

  formSetup(formId: string, params: Params, appMode: AppMode, overrides: Overrides, cfmObj: CFMObj): void {
    // console.log(overrides.agentEmail)
    if (!params.agents.find((agent) => agent.email.toLowerCase() === overrides.agentEmail)) {
      overrides.agentEmail = '';
    }
    // console.log(overrides)

    // console.log(formId)
    this.formValues[formId] = {
      appMode: appMode,
      overrides: overrides,
      meta: params.meta,
      metaUser: params.meta?.user,
      agents: params.agents,
      topics: params.topics,
      regions: params.regions,
      meetings: params.meetings,
      offices: params.offices,
      types: params.types,
      shareWith: params.shareWith,
      selectedTopicIds:
        cfmObj.selectedTopics.map((topicsText) => params.topics.find((topic) => topic.text === topicsText)?.id || '') ||
        [],
      selectedRegionId: params.regions.find((region) => region.text === cfmObj.region)?.id || '',
      selectedMeetingId: params.meetings.find((meeting) => meeting.text === cfmObj.meeting)?.id || '',
    };

    const formValue: FormValue = {
      agent: params.agents.find((agent) => agent.email.toLowerCase() === overrides.agentEmail),
      agentEmail: overrides.agentEmail,
      agents: params.agents,
      agentTimezone: '',
      comment: '',
      email: params.meta?.user.lastName || '',
      date: '',
      departure: [],
      firstName: params.meta?.user.firstName || '',
      lastName: params.meta?.user.lastName || '',
      meetings: params.meetings,
      phone: params.meta?.user.phone || '',
      regions: params.regions,
      timeSlot: cfmObj.timeSlot,
      topics: params.topics,
    };

    // console.log(cfmObj)
    this.buildForm(formId, formValue);
  }

  buildForm(formId: string, formValue: FormValue): void {
    // console.log(formValue)
    this.form[formId] = new FormGroup({
      agent: new FormControl(formValue.agent, []),
      agentTimezone: new FormControl(formValue.agentTimezone, []),
      comment: new FormControl(formValue.comment, { validators: this.validatorService.validateCommentFunction() }),
      date: new FormControl(formValue.date, []),
      departure: new FormControl(formValue.departure),
      email: new FormControl(formValue.email),
      firstName: new FormControl(formValue.firstName),
      lastName: new FormControl(formValue.lastName),
      meetings: new FormArray(formValue.meetings.map((meeting) => new FormControl(!!meeting.default))),
      phone: new FormControl(formValue.phone),
      regions: new FormArray(formValue.regions.map((region) => new FormControl(!!region.default))),
      timeSlot: new FormControl(formValue.timeSlot || {}, []),
      topics: new FormArray(formValue.topics.map(() => new FormControl(false))),
    });
  }
}
