<ng-container *ngIf="globalState$ | async as g">
  <ng-container *ngIf="blockState$ | async as b">
    <!-- column -->
    <ng-container *ngIf="!b.columnsAsCards.enabled" [ngTemplateOutlet]="columnTemplate"></ng-container>

    <!-- column as link with <a> wrapper -->
    <a
      *ngIf="b.columnsAsCards.enabled && b.columnsAsCards.links.length === b.blockValue.columns.length"
      [href]="b.columnsAsCards.links[columnIndex]?.targetPath"
      style="
        display: block;
        position: relative;
        color: #00a0df;
        background-color: #e6e6e6;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.01s;
      "
      [style.overflow]="g.selectedField ? undefined : 'hidden' // undefined: so that editor bubble does not get clipped"
      [style.background-color]="b.columnsAsCards.colors[columnIndex]?.background"
      [style.color]="b.columnsAsCards.colors[columnIndex]?.text"
      (mouseover)="setBackgroundColor(columnIndex, true)"
      (mouseout)="setBackgroundColor(columnIndex, false)"
      (click)="cardClick($event, b.columnsAsCards.links[columnIndex])"
    >
      <ng-container [ngTemplateOutlet]="columnTemplate"></ng-container>
    </a>

    <ng-template #columnTemplate>
      <div
        *ngIf="b.blockValue"
        style="
          display: flex;
          border-radius: 10px;
          border: none;
          outline: none;
          position: relative;
          justify-content: center;
        "
        [style.align-items]="
          b.blockValue.options.alignment === 'center' || (b.background?.url && b.blockWidth <= 900) ? 'center' : 'start'
        "
        [style.flex-direction]="rowsFlexDirection"
        [style.gap.px]="10"
        [style.box-shadow]="highlight[stateId + '-' + columnIndex.toString() + '-'] ? border.boxShadow : undefined"
        [style.border]="border.border"
        [style.border-radius.px]="
          highlight[stateId + '-' + columnIndex.toString() + '-'] ? border.borderRadiusPx : undefined
        "
        [style.outline]="border.outline"
        [style.width.px]="columnWidth"
      >
        <ng-container *ngFor="let rowId of b.blockValue.columns[columnIndex]?.rows; let rowIndex = index">
          <ava-multiblock-view-row
            [columnIndex]="columnIndex"
            [rowIndex]="rowIndex"
            [stateId]="stateId"
            [rowId]="rowId"
          ></ava-multiblock-view-row>
        </ng-container>

        <div
          *ngIf="columnIndex && b.blockValue.options.columnDivider.enabled"
          style="position: absolute; top: 0; box-shadow: inset -1px 0 0 0 #e6e6e6; height: 100%; width: 1px"
          [style.left.px]="-b.blockValue.options.columnGap.px / 2"
        ></div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
