import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { combineLatestWith, tap } from 'rxjs';
import { CheckboxOptions } from '../form-field-options/options-interfaces';
import { HOOSIER_RX_STATE, HoosierService, HoosierState, View } from '../hoosier.service';
import { ValidateStatus } from '../../join-renew.service';

@Component({
  selector: 'ava-hoosier-checkbox',
  templateUrl: './checkbox.html',
  providers: [RxEffects],
})
export class CheckboxComponent implements OnInit {
  @Input() options: CheckboxOptions = {
    content: '',
    fieldName: undefined,
    fieldPath: [],
    validateGroup: [],
    validateStatus: null,
    asyncValidators: [],
    validators: [],
  };
  ValidateStatus = ValidateStatus;
  formGroup: FormGroup | undefined;
  formControl: FormControl | undefined;

  constructor(
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private rxEffects: RxEffects,
    private hoosierService: HoosierService,
  ) {
    rxEffects.register(this.toggleValidators$);
  }

  toggleValidators$ = this.hoosierState.select('showAllValidationErrors').pipe(
    combineLatestWith(
      this.hoosierState.select('showActiveGroupValidationErrors'),
      this.hoosierState.select('activeView'),
    ),
    tap(([showAllValidationErrors, showActiveGroupValidationErrors, activeView]: [boolean, boolean, View | null]) => {
      if (this.formControl && this.options.validators.length) {
        if (showAllValidationErrors) {
          this.formControl.addValidators(this.options.validators);
          this.formControl.updateValueAndValidity();
        }
        if (!showAllValidationErrors) {
          this.formControl.removeValidators(this.options.validators);
        }
        if (this.options.validateGroup.find((validateGroupItem) => activeView === validateGroupItem)) {
          if (showActiveGroupValidationErrors) {
            this.formControl.addValidators(this.options.validators);
            this.formControl.updateValueAndValidity();
          }
          if (!showActiveGroupValidationErrors) {
            this.formControl.removeValidators(this.options.validators);
          }
        }
      }
    }),
  );

  ngOnInit(): void {
    if (this.options.fieldName) {
      this.formGroup =
        (this.hoosierService.form.get(this.options.fieldPath) as FormGroup) || (this.hoosierService.form as FormGroup);
      this.formControl = this.formGroup.get(this.options.fieldName) as FormControl;
    }
  }
}
