<div #componentWidth>
  <ng-container *ngIf="hoosierState$ | async as h">
    <div style="display: flex; gap: 16px; flex-direction: column; padding: 16px">
      <ng-container *ngFor="let member of members; let membersIndex = index; let last = last">
        <div style="display: flex" [style.flex-direction]="flexDirection">
          <div style="flex: 1; display: flex; gap: 16px; flex-direction: column; padding: 16px">
            <div style="display: flex" [style.flex-direction]="flexDirection">
              <div style="flex: 1">
                {{ membersIndex === 0 ? 'Primary Member:' : 'Household Member:' }}
                {{ membersIndex > 0 ? membersIndex.toString() : '' }}
              </div>
              <div style="flex: 1; font-weight: 600">{{ member.fullName }}</div>
            </div>

            <div style="display: flex" [style.flex-direction]="flexDirection">
              <div style="flex: 1">Membership Level:</div>
              <div style="flex: 1">{{ member.membershipLabel }}</div>
            </div>

            <div *ngIf="member.membershipNumber" style="display: flex" [style.flex-direction]="flexDirection">
              <div style="flex: 1">Membership Number:</div>
              <div style="flex: 1">{{ member.membershipNumber }}</div>
            </div>

            <div *ngIf="member.expirationDate" style="display: flex" [style.flex-direction]="flexDirection">
              <div style="flex: 1">Valid Thru:</div>
              <div style="flex: 1">{{ member.expirationDate }}</div>
            </div>

            <div
              *ngIf="member.membershipUserId && h.activeFlow === Flow.JOIN"
              style="display: flex"
              [style.flex-direction]="flexDirection"
            >
              <div style="flex: 1">User ID:</div>
              <div style="flex: 1">{{ member.membershipUserId }}</div>
            </div>
          </div>

          <div style="flex: 1; display: grid; place-content: center">
            <ava-hoosier-membership-card [membershipCardData]="member"></ava-hoosier-membership-card>
          </div>
        </div>

        <nz-divider *ngIf="!last"></nz-divider>
      </ng-container>
    </div>
  </ng-container>
</div>
