import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { MultiblockViewComponent } from '../block/view';
import { MultiblockViewColumnComponent } from '../block/view-column';
import { MultiblockViewRowComponent } from '../block/view-row';
import { BlockStateViewComponent } from '../block/wrappers/state-wrapper';
import { BlockWrapperViewComponent } from '../block/wrappers/page-width-wrapper';

import { ButtonViewComponent } from '../fields/button/view';
import { FlagViewComponent } from '../fields/flag/view';
import { InfoViewComponent } from '../fields/info/view';
import { ImageViewComponent } from '../fields/image/view';
import { JavascriptViewComponent } from '../fields/javascript/view';
import { QuillViewComponent } from '../fields/quill-editor/view';
import { QuillFormQuillInlineComponent } from '../fields/quill-editor/form-quill-inline';
import { VideoViewComponent } from '../fields/video/view';

import { GoogleMapsModule } from '@angular/google-maps';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
// import { SwiperModule } from "swiper/angular"
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InputComponent } from '../forms/input';
import { SelectComponent } from '../forms/select';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { RadioComponent } from '../forms/radio';
import { ComparisonChartViewComponent } from '../fields/comparison-chart/view';
import { DigitOnlyDirective } from '../directives/digit-only';
import { QuillViewAccordionComponent } from '../fields/quill-editor/view-accordion';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { HoosierModule } from './join-renew/hoosier/hoosier.module';
import { ConfirmationJoinViewComponent } from './join-renew/hoosier/multiblocks/confirmation-join/view';
import { PriceChartCardViewComponent } from './join-renew/hoosier/multiblocks/price-chart-card/view';
import { PriceChartMemberQuantityViewComponent } from './join-renew/hoosier/multiblocks/price-chart-member-quantity/view';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { BatteryQuoterComponent } from './battery-quoter/battery-quoter.component';
import { AgentSchedulerModule } from './agent-scheduler/agent-scheduler.module';
import { SmartWeatherModule } from './smart-weather/smart-weather.module';

@NgModule({
  declarations: [
    ButtonViewComponent,
    BlockStateViewComponent,
    BlockWrapperViewComponent,
    ComparisonChartViewComponent,
    ConfirmationJoinViewComponent,
    DigitOnlyDirective,
    FlagViewComponent,
    InfoViewComponent,
    InputComponent,
    ImageViewComponent,
    JavascriptViewComponent,
    MultiblockViewColumnComponent,
    MultiblockViewComponent,
    MultiblockViewRowComponent,
    PriceChartCardViewComponent,
    PriceChartMemberQuantityViewComponent,
    QuillFormQuillInlineComponent,
    QuillViewAccordionComponent,
    QuillViewComponent,
    RadioComponent,
    SelectComponent,
    VideoViewComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    GoogleMapsModule,
    HoosierModule,
    NzButtonModule,
    NzDrawerModule,
    NzFormModule,
    NzInputModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzTabsModule,
    NzToolTipModule,
    NzPipesModule,
    NzIconModule,
    NzCollapseModule,
    NzSelectModule,
    NzDividerModule,
    NzAffixModule,
    QuillModule,
    ReactiveFormsModule,
    // SwiperModule,
    NzSpinModule,
    BatteryQuoterComponent,
    AgentSchedulerModule,
    SmartWeatherModule,
  ],
  exports: [
    InputComponent,
    SelectComponent,
    ButtonViewComponent,
    BlockStateViewComponent,
    BlockWrapperViewComponent,
    MultiblockViewComponent,
    MultiblockViewColumnComponent,
    MultiblockViewRowComponent,
    BlockStateViewComponent,
    ImageViewComponent,
    JavascriptViewComponent,
    QuillFormQuillInlineComponent,
    RadioComponent,
  ],
  providers: [],
})
export class MultiblockViewModule {}
