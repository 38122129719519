<!-- Load page-level block with anchor, page margins, lazy-loaded admin toolbar (if user is an admin), and visibility logic -->
<div
  #containerWidth
  *ngIf="'personalize says showBlock'"
  style="position: relative"
  [style.margin]="undefined // previewModeStyles.margin"
  [style.width.px]="undefined // previewModeStyles.width"
  [style.box-shadow]="border.boxShadow"
  [style.border]="border.border"
  [style.border-radius.px]="border.borderRadiusPx"
  [style.outline]="border.outline"
  (mouseenter)="showToolbar()"
  (mouseleave)="hideToolbar()"
  (pointerup)="pointerUp($event)"
  (pointerdown)="pointerDown()"
  (click)="$event.stopPropagation()"
>
  <!-- position anchor to provide some space above the block when linking and auto-scrolling to it -->
  <div #blockAnchor style="display: inline-block; position: absolute; top: -100px"></div>

  <!-- content -->
  <ava-block-state-wrapper
    [blockId]="blockId"
    [stateId]="stateId"
    [parentColumnIndex]="pageColumnIndex"
    [parentRowId]="pageRowId"
    [parentRowIndex]="pageRowIndex"
    [parentStateId]="pageStateId"
  ></ava-block-state-wrapper>

  <!--Lazy-load admin module and these components (if user has permissions)-->
  <ng-container #toolbarContainer></ng-container>
  <!--  <ng-container #editFormContainer></ng-container>-->

  <!-- Overlays for insert-block and close-template-editor -->
  <ng-container *ngIf="globalState$ | async as g">
    <ng-container *ngIf="blockState$ | async as b">
      <ng-container *ngIf="b.selected && !g.createPanelVisible && !g.editorVisible">
        <ng-container
          [ngTemplateOutlet]="toolTab"
          [ngTemplateOutletContext]="{ bottom: undefined, rowIndex: pageRowIndex, top: 0 }"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="toolTab"
          [ngTemplateOutletContext]="{ bottom: 0, rowIndex: pageRowIndex + 1, top: undefined }"
        ></ng-container>
      </ng-container>

      <!-- toolTab for adding blocks and closing the template editor -->
      <ng-template #toolTab let-bottom="bottom" let-rowIndex="rowIndex" let-top="top">
        <div
          style="
            position: absolute;
            left: 50%;
            height: 24px;
            display: flex;
            user-select: none;
            transform: translateX(-50%);
          "
          [style.align-items]="top === 0 ? 'start' : 'end'"
          [style.bottom.px]="bottom"
          [style.top.px]="top"
        >
          <div class="ant-btn-purple" style="height: 2px; flex-grow: 1"></div>
          <div
            class="ant-btn-purple"
            style="color: white; border-radius: 20px; text-align: center; padding: 2px 20px; cursor: pointer"
            [style.border-bottom-left-radius.px]="top === 0 ? 20 : 0"
            [style.border-bottom-right-radius.px]="top === 0 ? 20 : 0"
            [style.border-top-left-radius.px]="bottom === 0 ? 20 : 0"
            [style.border-top-right-radius.px]="bottom === 0 ? 20 : 0"
            (click)="openCreatePanel($event, rowIndex)"
          >
            {{ b.blockValue?.blockTemplate ? 'Close Template Editor' : 'Insert Block Here' }}
          </div>
          <div class="ant-btn-purple" style="height: 2px; flex-grow: 1"></div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
