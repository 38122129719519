<ng-container *ngIf="blockState$ | async as b">
  <nz-collapse [nzBordered]="true" style="width: 100%">
    <ng-template #header>
      <div style="display: flex; gap: 10px">
        <div [innerHtml]="b.blockValue.rows[rowId]['heading'] | nzSanitizer: 'html'"></div>
        <div style="margin-left: auto">
          <i
            nz-icon
            nzType="right"
            nzTheme="outline"
            [style.transform]="active ? 'rotate(90deg)' : undefined"
            [style.transition]="'transform 0.24s'"
          ></i>
        </div>
      </div>
    </ng-template>

    <nz-collapse-panel [nzHeader]="header" [nzShowArrow]="false" [nzActive]="active" (nzActiveChange)="setActive()">
      <ava-quill-view
        [stateId]="stateId"
        [rowId]="rowId"
        [options]="options"
        [stylesFunction]="stylesFunction"
      ></ava-quill-view>
    </nz-collapse-panel>
  </nz-collapse>
</ng-container>
