import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Injectable()
export class PricePreviewEffects {
  store = inject(Store);
  actions$ = inject(Actions);

  // public loadPricePreviewAfterSucceededLoadedMembership$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MembershipActions.loadSucceeded),
  //     withLatestFrom(this.store.select(getPriceQuery)),
  //     map(([{ accountDetails }, pricePayload]) => {
  //       const payload = { ...pricePayload, zipCode: accountDetails.address.zip }
  //
  //       return PricePreviewActions.load({ payload })
  //     }),
  //   ),
  // )
}
