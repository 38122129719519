<div #container style="display: flex; justify-content: center">
  <div style="display: grid; grid-gap: 2px 10px" [ngStyle]="gridTemplateColumns">
    <img
      *ngIf="bs.clubDetails"
      [src]="bs.clubDetails.imageUrl"
      alt="battery quoter"
      width="360px"
      height="150px"
      style="grid-row: 1 / span 5"
    />

    <ng-template ngFor [ngForOf]="bs.steps" let-index="index">
      <div style="display: flex; flex-direction: column; padding: 10px">
        <nz-form-label [nzFor]="'select' + index" style="visibility: hidden; height: 0">Select Region</nz-form-label>
        <nz-select
          [nzId]="'select' + index"
          [nzLoading]="bs.inputStates[index] === bs.InputState.PROCESSING"
          [(ngModel)]="bs.selections[index]"
          (ngModelChange)="bs.processSelection(bs.steps[index], bs.selections)"
          [nzDisabled]="bs.inputStates[index] !== bs.InputState.READY"
        >
          <nz-option
            *ngFor="let option of bs.options[index]; first as first"
            [nzValue]="option"
            [nzLabel]="option"
            [nzDisabled]="first"
          ></nz-option>
        </nz-select>
      </div>
    </ng-template>

    <div style="padding: 10px" [ngStyle]="gridButtonColumn">
      <button
        nz-button
        nzType="primary"
        [nzLoading]="bs.inputButtonState === bs.InputState.PROCESSING"
        (click)="bs.setComponentState('buttonClicked', bs.componentStatePrevious, $event)"
      >
        {{ bs.inputButtonState === bs.InputState.WAITING ? 'All fields required for quote' : 'Get Battery Quote' }}
      </button>
    </div>
  </div>
</div>
