<div #container>
  @for (location of locations; track location; let first = $first; let last = $last) {
    @if (first) {
      <nz-divider [style]="'margin: 10px 0'" />
    }

    <div [style]="'display: grid; align-items: center'" [ngStyle]="locationRowStyles">
      <div [style]="'text-align: left'">
        {{ location.name }}
      </div>
      <div [style]="'display: grid; column-gap: 20px; grid-template-columns: 1fr 1fr'" [ngStyle]="buttonWrapperStyles">
        <button nz-button nzType="primary" (click)="swSms.sendEvent('EDIT', location.index)">Edit</button>
        <button nz-button nzType="primary" (click)="swSms.sendEvent('DELETE', location.index)">Delete</button>
      </div>
    </div>

    @if (!last || true) {
      <nz-divider [style]="'margin: 10px 0'" />
    }
  }

  <div [style]="'display: grid'" [ngStyle]="addAlertRowStyles">
    <div></div>

    @if (state.value !== 'cloudSyncError' && locations?.length < 5 && state.value !== 'cloudSync') {
      <button nz-button nzType="primary" (click)="swSms.sendEvent('EDIT', locations?.length)">Add New Alert</button>
    }
  </div>
</div>

<nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzFooter]="" nzWidth="90%">
  <ava-smart-weather-location-form
    *nzModalContent
    [eventIndex]="state.event.index"
    [title]="formTitle"
  ></ava-smart-weather-location-form>
</nz-modal>
