import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { PricePreviewActions } from './price-preview.actions';
import { SaveCacheSettings } from '@aaa/interface-joinRenew-joinRenewLib';
import { getCurrentTimeStamp } from '../utils/get-current-timestamp';
import { M } from '../m.type';
import {
  MembershipMMethod,
  MembershipMPricePreviewsEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipM';
import { ExecuteService } from '../services/execute.service';
import { mMembershipOffersMapping } from './helpers/m-membership-offer.mapping';
import { ClubApp } from '@aaa/emember/types';
import { filterByClubId } from '../utils/filter-by-club-id';
import { catchError } from 'rxjs/operators';
import { RequestError, RequestErrorType } from '../generic-errors';
import { getZipcode } from '@aaa/emember/store-membership';

@Injectable({ providedIn: 'root' })
export class PricePreviewShelbyEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubId(this.store, ClubApp.Shelby));
  executeService = inject(ExecuteService);

  public loadPricePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricePreviewActions.load),
      withLatestFrom(this.store.select(getZipcode)),
      switchMap(([{ payload }, zipcode]) => {
        const cacheSettings: SaveCacheSettings = {
          cacheType: 'save',
          context: 'globalContext',
          maxAge: getCurrentTimeStamp(-24 * 3600 * 1000),
        };
        const requestPayload: MembershipMPricePreviewsEventPayload = {
          cacheSettings: cacheSettings,
          method: MembershipMMethod.PRICE_PREVIEWS,
          promoCode: payload.promoCode,
          zip: zipcode,
        };

        return this.executeService.membershipQuery<M.PricePreviewsResponseObject>(requestPayload).pipe(
          map(({ error, response }) => {
            if (error && error?.data?.Result?.$?.errorText?.startsWith('Invalid Club Number')) {
              return PricePreviewActions.loadFailed({
                error: new RequestError(RequestErrorType.PricePreviewInvalidClubNumber),
              });
            }

            if (error) {
              return PricePreviewActions.loadFailed({ error: new RequestError(RequestErrorType.PricePreviewError) });
            }

            const offerings = response ? response.pricePreviewsResponse.Result.MembershipOffering : [];
            const prices = mMembershipOffersMapping(offerings);

            return PricePreviewActions.loadSucceeded({ prices });
          }),
          catchError(() =>
            of(PricePreviewActions.loadFailed({ error: new RequestError(RequestErrorType.PricePreviewError) })),
          ),
        );
      }),
    ),
  );
}
