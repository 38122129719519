import { Component, Input, OnInit } from '@angular/core';
import { StateMachineService } from '../../../services/state-machine.service';
import { FormService } from '../../../services/form.service';
import { FormArray } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { FormValues } from '@aaa/interface-agentScheduler';

@Component({
  selector: 'ava-topic',
  templateUrl: './topic.component.html',
})
export class TopicComponent implements OnInit {
  @Input() formId: string | undefined;
  formValues: FormValues | undefined;
  noTopicSelected: boolean = true;
  topics: FormArray | undefined;

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {}

  ngOnInit(): void {
    if (this.formId) {
      this.formValues = this.formService.formValues[this.formId];
      this.topics = this.formService.form[this.formId].get('topics') as FormArray;
    }
    this.noTopicSelected = !this.topics?.controls.find((topic) => topic.value);

    if (environment.ngServe) {
      // this.toggleTopic(0)
      // this.sms.sendEvent('NEXT')
    }
  }

  toggleTopic(index: number): void {
    this.topics?.controls[index].setValue(!this.topics?.controls[index].value);
    /**
     * The form values for topics are simple true or false.
     * We map from formService.topics to formService.selectedTopics with each topic selection change.
     */
    if (this.formValues) {
      this.formValues.selectedTopicIds = [];
      this.topics?.controls.forEach((topicControl, topicControlIndex) => {
        if (topicControl.value) {
          this.formValues?.selectedTopicIds.push(this.formValues.topics[topicControlIndex].id);
        }
      });
      this.noTopicSelected = !this.topics?.controls.find((topic) => topic.value);
    }
  }
}
