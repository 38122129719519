<ng-container *ngIf="hoosierState$ | async as h">
  <a
    *ngIf="typeCard"
    nz-button
    nz-type="default"
    class="ant-btn-dark-blue"
    [href]="priceChartCard.link.targetPath || priceChartCardLink.targetPath"
    style="position: relative; display: flex; color: white; font-size: 24px; max-width: 440px"
    [style.background-color]="backgroundColors[priceChartCard.membership.selectedLevel]"
    (click)="linkClick($event)"
  >
    <ng-container [ngTemplateOutlet]="TemplateCard"></ng-container>
  </a>

  <a
    *ngIf="typeCTA && priceChartCard?.description.cta"
    [href]="priceChartCardLink?.targetPath"
    style="position: relative; display: flex; border: solid 2px"
    [style.background-color]="
      priceChartCard.membership.selectedRv ? undefined : backgroundColors[priceChartCard.membership.selectedLevel]
    "
    [style.border-color]="backgroundColors[priceChartCard.membership.selectedLevel]"
    [style.color]="
      priceChartCard.membership.selectedRv ? backgroundColors[priceChartCard.membership.selectedLevel] : 'white'
    "
    (click)="linkClick($event)"
  >
    <ng-container [ngTemplateOutlet]="TemplateCTA"></ng-container>
  </a>

  <ng-template #TemplateCard>
    <div
      style="width: 200px; height: 150px; display: flex; align-items: center; justify-content: center; font-weight: 100"
    >
      <div style="width: 120px; height: 75px">
        <ava-image-view
          [stateId]="stateId"
          [fieldPath]="['blockValue', 'rows', rowId.toString(), 'image', 'attributes']"
          [style]="imageStyle"
        ></ava-image-view>
      </div>
    </div>
    <div
      style="
        width: 240px;
        font-weight: 100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
      "
    >
      <div style="font-weight: 300; font-size: 36px">{{ priceChartCard.description.title }}</div>
      <div style="font-weight: 300; font-size: 20px">
        <span style="font-weight: 500">{{ priceChartCard.description.towingMiles }}</span>
        of towing
      </div>
      <div style="font-weight: 300; font-size: 20px">{{ price | currency }} Per Year</div>
      <div style="display: flex; gap: 5px; align-items: center; font-weight: 500; font-size: 16px; padding-top: 10px">
        {{ priceChartCard.description.cta }}
        <i
          *ngIf="
            priceChartCardService.cardClicked !== priceChartCard.membership.selectedLevel ||
            h.activeDestination !== JoinSection.RV_COVERAGE
          "
          nz-icon
          nzType="icomoon:chevron-right"
          nzTheme="outline"
          style="color: red; font-size: 12px"
        ></i>
        <nz-spin
          *ngIf="
            priceChartCardService.cardClicked === priceChartCard.membership.selectedLevel &&
            h.activeDestination === JoinSection.RV_COVERAGE
          "
          nzSimple
        ></nz-spin>
      </div>
    </div>

    <div
      *ngIf="priceChartCard.flag.text.length || priceChartCard.flag.enabled"
      style="position: absolute; top: 1px; right: 1px; display: flex; height: 26px"
    >
      <div
        style="width: 0; height: 0"
        [style.border-top]="'26px solid white'"
        [style.border-bottom]="'none'"
        [style.border-left]="'10px solid transparent'"
      ></div>
      <div style="padding: 5px 13px; background-color: white; color: black; font-size: 12px">
        {{ priceChartCard.flag.text }}
      </div>
    </div>
  </ng-template>

  <ng-template #TemplateCTA>
    <div style="display: grid; place-content: center; padding: 12px 0; width: 100%">
      <div style="font-size: 18px; font-weight: 200; text-align: center">{{ priceChartCard.description.cta }}</div>
    </div>
  </ng-template>
  <!--  <ng-template #indicatorTemplate><span nz-icon nzType="loading"></span></ng-template>-->
</ng-container>
