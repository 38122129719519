<div style="display: flex; gap: 10px" [style.flex-direction]="flexDirection">
  <div style="flex: 1">
    <nz-skeleton-element *ngIf="!fieldsLoaded" nzBlock="true" nzType="input" [nzActive]="true"></nz-skeleton-element>
    <div #numberElement></div>
  </div>

  <div style="flex: 1; display: flex; gap: 10px">
    <div #CreditCardTypeImage style="width: 56px; height: 38px; position: relative">
      <div style="font-size: 48px; position: absolute; top: -9px; left: 0">
        <i
          *ngIf="cybsCardType"
          nz-icon
          [nzType]="cardIcons[cybsCardType] || 'icons:000-generic-credit-card'"
          style="padding-top: 2px"
        ></i>
        <i *ngIf="!cybsCardType" nz-icon nzType="icons:000-generic-credit-card" style="padding-top: 2px"></i>
      </div>
    </div>
    <nz-skeleton-element
      *ngIf="!fieldsLoaded"
      nzType="input"
      nzBlock="true"
      [nzActive]="true"
      style="width: 100%"
    ></nz-skeleton-element>
    <div #securityCodeElement></div>
  </div>
</div>
