import { createReducer, on } from '@ngrx/store';
import { PricePreviewActions } from './price-preview.actions';
import { RequestStatus } from '../../../types/request-status';
import { PricePreviewState } from './price-preview.model';

export const initialStatePricePreview: PricePreviewState = {
  priceQuery: { promoCode: '', couponCode: '' },
  pricesMap: {},
  prices: [],
  requestStatus: RequestStatus.UNSENT,
  error: null,
  memberQuantity: 1,
};

export const pricePreviewReducer = createReducer(
  initialStatePricePreview,
  on(PricePreviewActions.load, (state, { payload }) => ({
    ...state,
    error: null,
    priceQuery: payload,
    pricesMap: {},
    prices: [],
    requestStatus: RequestStatus.RUNNING,
  })),
  on(PricePreviewActions.loadSucceeded, (state, { prices }) => ({
    ...state,
    prices: prices.map((price) => price.membershipCode),
    pricesMap: prices.reduce((acc, price) => ({ ...acc, [String(price.membershipCode)]: price }), {}),
    requestStatus: RequestStatus.SUCCESS,
  })),
  on(PricePreviewActions.loadFailed, (state, { error }) => ({
    ...state,
    error,
    pricesMap: {},
    prices: [],
    requestStatus: RequestStatus.FAILED,
  })),
  on(PricePreviewActions.increaseMemberQuantity, (state) => {
    const firstPrice = state.pricesMap[state.prices[0]];
    const totalMembers = firstPrice?.prices.length || 0;
    const maximQuantity = firstPrice?.prices[totalMembers - 1]?.members || 1;

    return {
      ...state,
      memberQuantity: state.memberQuantity < maximQuantity ? state.memberQuantity + 1 : maximQuantity,
    };
  }),
  on(PricePreviewActions.decreaseMemberQuantity, (state) => ({
    ...state,
    memberQuantity: state.memberQuantity > 1 ? state.memberQuantity - 1 : 1,
  })),
);
